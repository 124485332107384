import React, { useState, useEffect } from 'react';
import HasSections from './HasSections.js';
import MaterialSearch from './MaterialSearch.js';
import EditableModal from './EditableModal';
import Swal from 'sweetalert2';

export default function HotbarItemSettings(props) {
  const { config, setConfig, currentSlot, setHotbarSettingsView, hotbarSettingsView, panelName } = props;
  const [material, setMaterial] = useState('');
  const [name, setName] = useState('');
  const [lore, setLore] = useState([]);
  const [rawLore, setRawLore] = useState('');
  const [stack, setStack] = useState('');
  const [damage, setDamage] = useState('');
  const [potion, setPotion] = useState('');
  const [potionColor, setPotionColor] = useState('');
  const [itemType, setItemType] = useState([]);
  const [rawItemType, setRawItemType] = useState('');
  const [enchanted, setEnchanted] = useState([]);
  const [rawEnchanted, setRawEnchanted] = useState('');
  const [armorTrim, setArmorTrim] = useState('');
  const [leatherArmor, setLeatherArmor] = useState('');
  const [customData, setCustomData] = useState('');
  const [stationary, setStationary] = useState('');
  const [commands, setCommands] = useState([]);
  const [rawCommands, setRawCommands] = useState('');
  const [banner, setBanner] = useState([]);
  const [rawBanner, setRawBanner] = useState('');

  const [hotbarSection, setHotbarSection] = useState('');

  const getItemProperties = (item, hotbarSection) => {
    if (!hotbarSection) {
      return item;
    }

    const keys = hotbarSection.split('.');
    let currentItem = item;
    for (const key of keys) {
      currentItem = currentItem?.[key];
    }
    return currentItem;
  };

  useEffect(() => {
    const item = getItemProperties(config['open-with-item'], hotbarSection);
    if (item) {
      setMaterial(item.material || '');
      setName(item.name || '');
      setLore(item.lore || []);
      setRawLore(item.lore?.join('\n') || '');
      setStack(item.stack || '');
      setDamage(item.damage || '');
      setPotion(item.potion || '');
      setPotionColor(item['potion-color'] || []);
      setItemType(item['itemType'] || []);
      setRawItemType(item['itemType']?.join('\n') || '');
      setEnchanted(Array.isArray(item.enchanted) ? item.enchanted : []);
      setRawEnchanted(Array.isArray(item.enchanted) ? item.enchanted.join('\n') : '');
      setArmorTrim(item.trim || '');
      setLeatherArmor(item.leatherarmor || '');
      setCustomData(item.customdata || '');
      setStationary(item.stationary || '');
      setCommands(item.commands || []);
      setRawCommands(item.commands?.join('\n') || '');
      setBanner(item.banner || []);
      setRawBanner(item.banner?.join('\n') || '');
    } else {
      setMaterial('');
      setName('');
      setLore([]);
      setRawLore('');
      setStack('');
      setDamage('');
      setPotion('');
      setPotionColor('');
      setItemType([]);
      setRawItemType('');
      setEnchanted([]);
      setRawEnchanted('');
      setArmorTrim('');
      setLeatherArmor('');
      setCustomData('');
      setStationary('');
      setCommands([]);
      setRawCommands('');
      setBanner([]);
      setRawBanner('');
    }
  }, [config, currentSlot, hotbarSection]);

  const handleInputChange = (e, stateSetter, key) => {
    const value = e.target.value;
    stateSetter(value);  // Update local state with the new value

    const currentItem = getItemProperties(config['open-with-item'], hotbarSection);
    const updatedItem = { ...currentItem, [key]: value };
    let updatedConfigSection = {...config['open-with-item']}; // Start with a copy of the current config

    if (hotbarSection) {
        const path = hotbarSection.split('.');
        let target = updatedConfigSection;
        for (let i = 0; i < path.length - 1; i++) {
            // Ensure each part of the path exists
            target[path[i]] = target[path[i]] || {};
            target = target[path[i]];
        }
        // Set the new value on the last part of the path
        target[path[path.length - 1]] = updatedItem;
    } else {
        // If no path is provided, directly update the root of the open-with-item section
        updatedConfigSection = updatedItem;
    }

    // Send the updated config section back to the parent component or handler
    props.updatePanelSetting("open-with-item", updatedConfigSection);
};
  const handleListChange = (e, rawStateSetter, stateSetter, key) => {
    const rawValue = e.target.value;
    rawStateSetter(rawValue);
    const value = rawValue.split('\n');
    stateSetter(value);
    const currentItem = getItemProperties(config['open-with-item'], hotbarSection);
    const updatedItem = { ...currentItem, [key]: value };
    props.updatePanelSetting('open-with-item', updatedItem);
  };

  // Delete the item from the configuration
  const handleDeleteItem = () => {
    Swal.fire({
      title: 'This will delete all your hotbar item settings',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        setConfig((prevConfig) => {
          const updatedConfig = { ...prevConfig };
  
          // Delete the open-with-item slot
          delete updatedConfig['open-with-item'];
  
          return updatedConfig;
        });
      }
    });
  };  
  
  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '255px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
  };

  const labelStyle = {
    display: 'inline-block',
    width: '220px',
    marginBottom: '5px',
  };

  const buttonStyle = {
    position: 'absolute',
    width: '100px',
    right: '10px',
    padding: '5px',
  };
  
  const deleteButton = {
    position: 'absolute',
    backgroundColor: 'red',
    width: '100px',
    right: '120px',
    padding: '5px',
  };

  const textAreaStyle = {
    width: '455px',
    height: '100px',
    fontSize: '12px',
    marginBottom: '10px',
    marginLeft: '10px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    whiteSpace: 'pre',
    color: 'white',
    resize: 'none',
    overflow: 'auto',
    overflowWrap: 'normal',
    overflowX: 'scroll',
  };

  return (
    <div style={{ padding: '3px' }}>
      <button style={buttonStyle} onClick={() => setHotbarSettingsView(!hotbarSettingsView)}>
        {hotbarSettingsView ? "Advanced" : "Basic"}
      </button>
      {config['open-with-item'] && (
        <button style={deleteButton} onClick={handleDeleteItem}>
          {"Delete Item"}
        </button>
      )}
      <p>
        <label id="hotbarItemLabel" style={labelStyle}>Hotbar Item</label>
      </p>
      <div className="basic">
        <MaterialSearch textures={props.textures} handleInputChange={handleInputChange} material={material} setMaterial={setMaterial}/>
        <p>
          <label style={labelStyle}>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => handleInputChange(e, setName, 'name')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Stationary Slot:</label>
          <input
            type="text"
            value={stationary}
            onChange={(e) => handleInputChange(e, setStationary, 'stationary')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Custom Model Data:</label>
          <input
            type="text"
            value={customData}
            onChange={(e) => handleInputChange(e, setCustomData, 'customdata')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawLore}
            label="Lore:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawLore, setLore, 'lore');}}
          />
          <textarea
            value={rawLore}
            onChange={(e) => handleListChange(e, setRawLore, setLore, 'lore')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
      </div>
      {hotbarSettingsView && (
      <div className="advanced">
        <hr/>
        <p>
          <label style={labelStyle}>Stack:</label>
          <input
            type="text"
            value={stack}
            onChange={(e) => handleInputChange(e, setStack, 'stack')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Damage:</label>
          <input
            type="text"
            value={damage}
            onChange={(e) => handleInputChange(e, setDamage, 'damage')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion:</label>
          <input
            type="text"
            value={potion}
            onChange={(e) => handleInputChange(e, setPotion, 'potion')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion Color:</label>
          <input
            type="text"
            value={potionColor}
            onChange={(e) => handleInputChange(e, setPotionColor, 'potion-color')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawItemType}
            label="Item Type:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawItemType, setItemType, 'itemType');}}
          />
          <textarea
            value={rawItemType}
            onChange={(e) => handleListChange(e, setRawItemType, setItemType, 'itemType')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawEnchanted}
            label="Enchanted:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawEnchanted, setEnchanted, 'enchanted');}}
          />
          <textarea
            value={rawEnchanted}
            onChange={(e) => handleListChange(e, setRawEnchanted, setEnchanted, 'enchanted')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <p>
          <label style={labelStyle}>Armor Trim:</label>
          <input
            type="text"
            value={armorTrim}
            onChange={(e) => handleInputChange(e, setArmorTrim, 'trim')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Leather Armor:</label>
          <input
            type="text"
            value={leatherArmor}
            onChange={(e) => handleInputChange(e, setLeatherArmor, 'leatherarmor')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawCommands}
            label="Commands:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawCommands, setCommands, 'commands');}}
          />
          <textarea
            value={rawCommands}
            onChange={(e) => handleListChange(e, setRawCommands, setCommands, 'commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawBanner}
            label="Banner:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawBanner, setBanner, 'banner');}}
          />
          <textarea
            value={rawBanner}
            onChange={(e) => handleListChange(e, setRawBanner, setBanner, 'banner')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <label style={labelStyle}>Has Sections:</label>
        <HasSections 
          setConfig={setConfig} 
          config={config} 
          currentSlot={"open-with-item"}
          panelName={panelName} 
          currentSection={hotbarSection} 
          setCurrentSection={setHotbarSection}/>
      </div>
      )}
    </div>
  );
};