import React, { useEffect } from 'react';
import InventoryItem from './InventoryItem';
import "../../css/editor/inventory.css";
import LoadingBar from '../util/loadingBar';

export default function InventoryGrid(props) {

  const [history, setHistory] = React.useState([]);

  const { config, setConfig, panelName, currentSlot, currentSection, handleOnClickSlot, textures, isLoading, currentAnimateFrame } = props;

  const inventoryItems = config.item;
  const rows = config.rows;
  const cols = 9;
  const totalSlots = Math.min(rows, 6) * cols;

  function handleRestoreHistory(event) {
    if (event.key === 'z' && event.ctrlKey) {
      if (history.length > 0) {
        const updatedConfig = { ...config };
        const latest = history[history.length - 1];
        const slot = latest.slot;
        const contents = latest.contents;
  
        if (contents !== null && typeof contents !== 'undefined') {
          updatedConfig["item"][slot] = contents;
        } else {
          delete updatedConfig["item"][slot];
        }
        setConfig(updatedConfig);
        setHistory(history.slice(0, history.length - 1));
      }
    }
  }  

  const getItemProperties = (item, currentSection, currentAnimateFrame) => {
    const keys = currentSection?.split('.') ?? [];
    const currentItem = keys.reduce((acc, key) => acc?.[key], item);
    return currentItem?.[currentAnimateFrame] ? currentItem?.[currentAnimateFrame] : currentItem;
  };

  const renderSlots = () => {
    const slots = [];
    for (let i = 0; i < totalSlots; i++) {
      const isSelected = i === currentSlot;
      const item = inventoryItems?.[i];
      const currentItemProperties = getItemProperties(item, isSelected ? currentSection : undefined, currentAnimateFrame);
      const isEmptySlot = typeof item === 'undefined';

      slots.push(
        <InventoryItem
          history={history}
          setHistory={setHistory}
          handleOnClickSlot={handleOnClickSlot}
          textures={textures}
          isEmptySlot={isEmptySlot}
          currentSlot={currentSlot}
          config={config}
          setConfig={setConfig}
          panelName={panelName}
          current={{
            slot: i,
            material: currentItemProperties?.material,
            empty: config.empty,
            stack: currentItemProperties?.stack,
            name: currentItemProperties?.name,
          }}
          id={i}
          isSelected={isSelected}
          key={i}
        />
      );
    }
    return slots;
  };

  return (
    <div onKeyDown={handleRestoreHistory} className="inventoryContainer">
      {isLoading ? <LoadingBar text={"Loading Item Textures"} /> :
        <div className="inventoryContents">{renderSlots()}</div>
      }
    </div>
  );
}
