import yaml from "js-yaml";
import { listAll, getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Swal from 'sweetalert2';

export const exportPanel = async (user, fileName, fullConfig, panelName, config) => {
    const cleanedConfig = removeEmptyObjects(config);
    const updatedFullConfig = {
        ...fullConfig,
        [panelName]: cleanedConfig,
    };
    const wrappedConfig = {
        panels: updatedFullConfig,
    };
    const yamlString = yaml.dump(wrappedConfig);

    const path = user.uid + "/";
    const textToCopy = await addFirebaseFile(user, path, fileName, yamlString);
    return textToCopy;
};
  

export const importPanel = async (importedText, setFileName, setFullConfig, setConfig, setPanelName) => {
  try {
    const importedConfig = yaml.load(importedText);
    if (importedConfig && importedConfig.panels && importedConfig.panels[Object.keys(importedConfig.panels)[0]]) {
      const newFileName = importedConfig.fileName ? importedConfig.fileName : 'exampleFile';
      setFileName(newFileName);
      setFullConfig(importedConfig.panels);
      const newPanelName = Object.keys(importedConfig.panels)[0];
      setConfig(importedConfig.panels[newPanelName]);
      setPanelName(newPanelName);
    } else {
      alert("Invalid configuration.");
    }
  } catch (error) {
    alert("Error parsing YAML: " + error.message);
  }
};

//removes empty setions of the yaml before export
export const removeEmptyObjects = (obj) => {
  const cleanedObj = {};
  for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          // These sections will be skipped and not removed if empty
          if (key.startsWith('value') || key.startsWith('compare')) {
              cleanedObj[key] = value;
              continue;
          }
          if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
              const cleanedValue = removeEmptyObjects(value);
              if (Object.keys(cleanedValue).length > 0) {
                  cleanedObj[key] = cleanedValue;
              }
          } else if (Array.isArray(value)) {
              const cleanedArray = value.filter((item) => item !== '');
              if (cleanedArray.length > 0) {
                  cleanedObj[key] = cleanedArray;
              }
          } else if (value !== '') {
              cleanedObj[key] = value;
          }
      }
  }
  return cleanedObj;
};

const addFirebaseFile = async (user, path, fileName, fileContents) => {
  const storage = getStorage();

  // Check the number of files in the user's folder
  const folderRef = ref(storage, `pastes/${path}`);
  const fileList = await listAll(folderRef);
  const fileExists = fileList.items.some((item) => item.name === `${fileName}.yml`);
  
  if (!fileExists && fileList.items.length >= 50) {
    Swal.fire({
      icon: 'warning',
      title: 'Maximum Panels',
      text: 'You have reached the maximum number of files (50). Please delete some files before uploading.',
      confirmButtonText: 'OK',
    })
    //throw error so that function does not run
    throw new Error("You have reached the maximum number of files (50). Please delete some files before uploading.");
  }

  // Modify the path to include the "pastes" folder
  const fullPath = `pastes/${path}${fileName}.yml`;
  const storageRef = ref(storage, fullPath);

  // Convert the YAML string to a Blob
  const yamlBlob = new Blob([fileContents], { type: 'text/yaml' });

  // Upload the Blob to Firebase Storage
  await uploadBytes(storageRef, yamlBlob);
  const downloadURL = await getDownloadURL(storageRef);

  // Get the token from the download URL
  const urlParams = new URLSearchParams(downloadURL.slice(downloadURL.indexOf('?') + 1));
  const token = urlParams.get('token');
  // Send the command to download the panel
  const text = `/cpe ${fileName}.yml ${user.uid} ${token}`;
  return text;
};