import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Footer from "./Footer";

export default function Layout({toggleHamburger, setToggleHamburger, activeLink, setActiveLink}) {
  
  return (
    <>
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} toggleHamburger={toggleHamburger} setToggleHamburger={setToggleHamburger}/>
      <Outlet />
      {(activeLink === "home" || activeLink === "wiki" || activeLink === "repository" || activeLink === "download") && <Footer/>}
    </>
  )
}