import React, { useState, useEffect } from 'react';

export default function InventoryItem(props) {
  const { history, setHistory, config, setConfig, panelName, currentSlot, isEmptySlot, handleOnClickSlot, isSelected, currentAnimateFrame } = props;
  const { slot, material, name, empty } = props.current;
  const [itemImageUrl, setItemImageUrl] = useState(null);

  const getStoragePath = (current) => {
    const { material, empty } = current;
  
    if (typeof material === 'string' && material !== 'AIR' && material !== '') {
      if (material.startsWith('cps=') || material.startsWith('hdb=') || material.startsWith('%cp-player-online')) {
        //default player head
        return `player_head.png`;
      } else if (material.startsWith('mmo=') || material.startsWith('cpi=')){
        //block that looks like pitch black placeholder for any block
        return `black_concrete.png`;
      } else if (material.startsWith('book=')){
        //book tag
        return `book.png`;
      } else {
        return `${material.toLowerCase()}.png`;
      }
    } else if (empty && isEmptySlot) {
      return `${empty.toLowerCase()}.png`;
    } else {
      return `air.png`;
    }
  }; 

  const handleSlotCopy = (prevSlot, newSlot) => {
    // if previous slot is empty, return without doing anything
    if (config["item"][prevSlot] === undefined) return;
  
    const updatedConfig = { ...config };
    updatedConfig["item"][newSlot] = updatedConfig["item"][prevSlot];
    setConfig(updatedConfig);
  };  

  const deleteSlot = (slot) => {
    //JSON.parse and stringify required to make a deep copy so that any copied panels are not affected
    const updatedConfig = JSON.parse(JSON.stringify(config));
    // push a new slot into the history
    const slotHistory = {
      slot: slot,
      contents: updatedConfig["item"][slot],
    };
    setHistory([...history, slotHistory]);
    delete updatedConfig["item"][slot];
    setConfig(updatedConfig);
}


  function handleKeyDown(event) {
    if (event.key === 'Delete') {
      deleteSlot(slot);
    }
  }

  function updateSlot(event) {
    // copy the contents of this slot into the next one
    if (event.shiftKey) {
      handleSlotCopy(currentSlot, slot);
    }

    // update the current slot
    handleOnClickSlot(slot);
  }

  useEffect(() => {
    let path = getStoragePath(props.current);
    for (let i = 0; i < props.textures.length; i++) {
      if (props.textures[i].fileName === path) {
        setItemImageUrl(props.textures[i].url);
        return;
      }
    }
  }, [props.current]);

  const inventoryItemClass = `inventoryItem ${isSelected ? 'selected' : ''}`;

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} className={inventoryItemClass} onClick={updateSlot}>
      <div className={`slotImage ${isSelected ? 'selected' : ''}`}>
        {itemImageUrl && (
          <img
            src={itemImageUrl}
            alt={typeof name === 'string' ? name.slice(0, 6) : ''}
            className="itemImage"
          />
        )}
      </div>
    </div>
  );
}
