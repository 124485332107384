import { registerWithEmailAndPassword } from '../../firebase/firebase';
import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../../css/auth/signup.scss";
import logo from '../../images/faviconBlank.png';
import Swal from 'sweetalert2';

function SignUpPage() {
  const navigate = useNavigate();

  const displayNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  function validateEmail(email) {
    const re = /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)$/;
    return re.test(String(email).toLowerCase());
  }

  async function handleSignUpWithEmailAndPassword(e) {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const displayName = displayNameRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    // any required field is empty
    if (email === "" || password === "" || displayName === "") {
      Swal.fire('Error', 'You must enter a display name, email and password!', 'error');
      return;
    }
    // not valid email
    if (!validateEmail(email)) {
      Swal.fire('Error', 'Please ensure you enter a valid email.', 'error');
      return;
    }
    // passwords do not match
    if (password !== confirmPassword) {
      Swal.fire('Error', 'Passwords do not match!', 'error');
      return;
    }
    // password is not valid
    if (!isValidPassword(password)) {
      Swal.fire('Error', 'Not a valid password, A valid password must have at least 7 characters, 1 capital letter, and one special character (eg: *, ! ^ etc)', 'error');
      return;
    }
    try {
      await registerWithEmailAndPassword(displayName, email, password);
    } catch(err) {
      console.error(err);
      Swal.fire('Error', err.message, 'error');
    }
    navigate("/");
  }

  function isValidPassword(password) {
    if (password.length < 8) {
      return false;
    }
    if (!password.match(/[A-Z]/)) {
      return false;
    }
    if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
      return false;
    }
    return true;
  }

  return (
    <div className="authContainer">
      <div id="signupContainer" className="container">
        <header>
          <img src={logo} alt="logo" className="login_logo"/>
        </header>
        <div className="body">
          <h1>Sign up</h1>
          <form>
            <label htmlFor="displayName">Display Name:</label>
            <input ref={displayNameRef} type="text" name="displayName" id="displayName" required></input>

            <label htmlFor="email">Email:</label>
            <input ref={emailRef} type="email" name="email" id="email" required></input>

            <label htmlFor="password">Password:</label>
            <input ref={passwordRef} type="password" name="password" id="password" required></input>

            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input ref={confirmPasswordRef} type="password" name="confirmPassword" id="confirmPassword" required></input>

            <button onClick={e => handleSignUpWithEmailAndPassword(e)} type="submit">Sign Up</button>

            <p id="validPasswordText">A valid password must have at least 8 characters, 1 capital letter, and one special character (eg: *, ! ^ etc)</p>

            <div className="signup">
              <p>Already have an account? <Link className="signupLink" to="/login">Login</Link></p>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default SignUpPage;