import React, { useRef } from "react";
import "../../css/auth/forgotPassword.scss";
import { sendPasswordReset } from "../../firebase/firebase";
import { Link } from "react-router-dom";
import logo from "../../images/faviconBlank.png";
import Swal from "sweetalert2";
import validator from "validator";

export default function ForgotPassword() {
  const emailRef = useRef(null);

  async function handlePasswordReset(e) {
    e.preventDefault();
    const email = emailRef.current.value;
    console.log("Email:", email);
    if (!validator.isEmail(email)) {
      Swal.fire("Error", "Please ensure you enter a valid email.", "error");
      return;
    }

    try {
      await sendPasswordReset(email);
      Swal.fire("Email Sent", "An email has been sent if an account exists under the email provided", "success");
    } catch (err) {
      Swal.fire("Error", "Failed to reset password", "error");
      console.error(err);
    }
  }

  return (
    <div className="authContainer">
      <div id="forgotPasswordContainer" className="container">
        <header>
          <img src={logo} alt="logo" className="login_logo"/>
        </header>
        <div className="body">
          <h1>Forgot Password</h1>
          <form>
            <br />
            <br />
            <label htmlFor="email">Account Email:</label>
            <input ref={emailRef} type="email" name="email" id="email" required />

            <button onClick={(e) => handlePasswordReset(e)} type="submit">
              Reset My Password
            </button>
          </form>

          <hr className="or-line" value="or" />

          <div className="signup">
            <p>
              <Link className="signupLink" to="/login">
                Return to login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
