import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "../../css/repository/upload.css";
import { useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebaseConfig";
import { createRepository, updateRepository, deleteRepository, getRepositories } from './UploadManager';
import yaml from 'js-yaml';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2';
import LoadingBar from '../util/loadingBar';

const UploadModify = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const panelId = params.get('panelId');

  const [user, loading] = useAuthState(auth);
  const [isLoading, setIsLoading] = useState(true);
  const [panels, setPanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState("default");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [commandPanelsVersion, setCommandPanelsVersion] = useState("");
  const [panelName, setPanelName] = useState("");
  const [minecraftVersion, setMinecraftVersion] = useState("");
  const [displayName, setDisplayname] = useState(user.displayName);
  const navigate = useNavigate();
  const isUpdate = !!panelId;

  useEffect(() => {
    fetchUserPanels();
    loadPanelData();
  }, [user]);

  const loadPanelData = async () => {
    if (!isUpdate){
        setIsLoading(false);
        return;
    }
    const repositories = await getRepositories(panelId);
    const panel = repositories[0];

    if(user.uid !== panel.owner){
        Swal.fire('Error', 'You do not have permission!', 'error');
        navigate('/repository');
        return;
    }

    if (panel) {
      setDescription(panel.description);
      setCommandPanelsVersion(panel.commandPanelsVersion);
      setPanelName(panel.name);
      setMinecraftVersion(panel.minecraftVersion);
      setDisplayname(panel.username);
    }
    setIsLoading(false);
};


  const fetchUserPanels = async () => {
    if (loading || !user) return;
    const storage = getStorage();
    const userUid = user.uid;
    const folderRef = ref(storage, `pastes/${userUid}`);
    const fileList = await listAll(folderRef);
    const panelNames = fileList.items.map(item => item.name);
    setPanels(panelNames);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        if([panelName, description, minecraftVersion, commandPanelsVersion, displayName].some(value => !value)){
            Swal.fire('Error', 'Required form/s are not complete', 'error');
            setIsLoading(false);
            return;
        }  
        if (selectedPanel === "default" && isUpdate) {
            // If 'Unchanged' is selected for the panel, don't update the panel
            await updateRepository(
                displayName,
                panelId,
                null, // don't update the panel
                null,
                panelName,
                description,
                commandPanelsVersion,
                minecraftVersion,
                image
            );
            Swal.fire('Success', 'Panel updated successfully', 'success');
        } else {
            const storage = getStorage();
            const userUid = user.uid;
            const panelRef = ref(storage, `pastes/${userUid}/${selectedPanel}`);
            const downloadURL = await getDownloadURL(panelRef);
            const response = await fetch(downloadURL);
            const panelContent = await response.text();

            if (isUpdate) {
              await updateRepository(
                  displayName,
                  panelId,
                  selectedPanel,
                  yaml.load(panelContent),
                  panelName,
                  description,
                  commandPanelsVersion,
                  minecraftVersion,
                  image
                  );           
              Swal.fire('Success', 'Panel updated successfully', 'success');
            } else {
                if([image].some(value => !value)){
                    Swal.fire('Error', 'Uploading an image is required', 'error');
                    setIsLoading(false);
                    return;
                }
                if (image.size > 500000) { // Check for 1MB in bytes (500KB = 500,000 bytes)
                    Swal.fire('Error', 'Image size should be less than 500KB', 'error');
                    setIsLoading(false);
                    return;
                }
                await createRepository(
                    user.uid,
                    displayName,
                    selectedPanel,
                    yaml.load(panelContent),
                    panelName,
                    description,
                    commandPanelsVersion,
                    minecraftVersion,
                    image
                    );          
            Swal.fire('Success', 'Panel uploaded successfully', 'success');
            }
        }
        navigate('/repository');
    } catch (error) {
      console.error('Error uploading panel:', error);
      Swal.fire('Error', isUpdate ? 'Failed to update panel' : 'Failed to upload panel', 'error');
      setIsLoading(false);
    }
  };  

  const handleDelete = async () => {
    if (!isUpdate) return;
    try {
        const result = await Swal.fire({
          title: `Are you sure you want to delete this upload?`,
          text: 'You will not be able to recover this data!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
      });
      if (result.isConfirmed) {
        setIsLoading(true);
        await deleteRepository(panelId);
        navigate('/repository');
      }
    } catch (error) {
      console.error('Error deleting panel:', error);
      Swal.fire('Error', 'Failed to delete panel', 'error');
    }
  };

  return (
    <div className="uploadModifyContainer_unique">
      {isLoading ? (
        <div className="uploadPanelPageLoading">
          <LoadingBar text={`Loading Panel Details`} />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="uploadModifyForm_unique">
            <label htmlFor="panelSelect" className="uploadModifyLabel_unique">Select Panel File</label>
            <select id="panelSelect" value={selectedPanel} onChange={(e) => setSelectedPanel(e.target.value)} className="uploadModifySelect_unique">
                <option value="default">{isUpdate ? 'Unchanged' : 'Choose a Panel...'}</option>
                {panels.map((panel, index) => (
                <option key={index} value={panel}>
                    {panel}
                </option>
                ))}
            </select>
            <label className="uploadModifyLabel_unique">
            Image (Maximum 500KB)
            <input type="file" accept="image/*" onChange={(e) => setImage(e.target.files[0])} className="uploadModifyInput_unique" />
            </label>
            <label className="uploadModifyLabel_unique">
            Panel Title
            <input type="text" value={panelName} onChange={(e) => setPanelName(e.target.value)} maxLength="32" className="uploadModifyInput_unique"/>
            </label>
            <label className="uploadModifyLabel_unique">
            Description (What the panel does, how to use it and does it need dependencies?)
            <textarea value={description} onChange={(e) => setDescription(e.target.value)} maxLength="300" className="uploadModifyTextarea_unique"/>
            </label>
            <label className="uploadModifyLabel_unique">
            CommandPanels Version/s Tested
            <input type="text" value={commandPanelsVersion} onChange={(e) => setCommandPanelsVersion(e.target.value)} maxLength="25" className="uploadModifyInput_unique"/>
            </label>
            <label className="uploadModifyLabel_unique">
            Minecraft Version/s Tested (eg, 1.16-1.20)
            <input type="text" value={minecraftVersion} onChange={(e) => setMinecraftVersion(e.target.value)} maxLength="25" className="uploadModifyInput_unique"/>
            </label>
            <label className="uploadModifyLabel_unique">
            Author Display Name
            <input type="text" value={displayName} onChange={(e) => setDisplayname(e.target.value)} maxLength="25" className="uploadModifyInput_unique"/>
            </label>
            <button type="submit" className="uploadModifyButton_unique uploadModifyButtonSubmit_unique">{isUpdate ? 'Update' : 'Upload'}</button>
            {isUpdate && <button type="button" onClick={handleDelete} className="uploadModifyButton_unique uploadModifyButtonDelete_unique">Delete</button>}
        </form>
      )}
    </div>
  );
};

export default UploadModify;
