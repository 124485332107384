import React, { useState, useEffect } from 'react';
import EditableModal from './EditableModal';
import MaterialSearch from './MaterialSearch';
import Swal from 'sweetalert2';

export default function ItemSettings(props) {
  const {setConfig, config, currentSlot, currentSection, currentAnimateFrame, setItemSettingsView, itemSettingsView } = props;
  const [material, setMaterial] = useState('');
  const [name, setName] = useState('');
  const [lore, setLore] = useState([]);
  const [rawLore, setRawLore] = useState('');
  const [stack, setStack] = useState('');
  const [damage, setDamage] = useState('');
  const [potion, setPotion] = useState('');
  const [potionColor, setPotionColor] = useState('');
  const [enchanted, setEnchanted] = useState([]);
  const [rawEnchanted, setRawEnchanted] = useState('');
  const [leatherArmor, setLeatherArmor] = useState('');
  const [armorTrim, setArmorTrim] = useState('');
  const [customData, setCustomData] = useState('');
  const [duplicate, setDuplicate] = useState('');
  const [commands, setCommands] = useState([]);
  const [rawCommands, setRawCommands] = useState('');
  const [refreshCommands, setRefreshCommands] = useState([]);
  const [rawRefreshCommands, setRawRefreshCommands] = useState('');
  const [itemType, setItemType] = useState([]);
  const [rawItemType, setRawItemType] = useState('');
  const [multiPaywall, setMultiPaywall] = useState([]);
  const [rawMultiPaywall, setRawMultiPaywall] = useState('');
  const [banner, setBanner] = useState([]);
  const [rawBanner, setRawBanner] = useState('');
  const [playerInput, setPlayerInput] = useState([]);
  const [rawPlayerInput, setRawPlayerInput] = useState('');

  const getItemProperties = (item, currentSection, currentAnimateFrame) => {
    const keys = currentSection?.split('.') ?? [];
    const currentItem = keys.reduce((acc, key) => acc?.[key], item);
    return currentAnimateFrame ? currentItem?.[currentAnimateFrame] : currentItem;
  };  

  useEffect(() => {
    const item = getItemProperties(config.item?.[currentSlot], currentSection, currentAnimateFrame);
    if (item) {
      setMaterial(item.material || '');
      setName(item.name || '');
      setLore(item.lore || []);
      setRawLore(item.lore?.join('\n') || '');
      setStack(item.stack || '');
      setDamage(item.damage || '');
      setPotion(item.potion || '');
      setPotionColor(item['potion-color'] || '');
      setEnchanted(Array.isArray(item.enchanted) ? item.enchanted : []);
      setRawEnchanted(Array.isArray(item.enchanted) ? item.enchanted.join('\n') : '');
      setLeatherArmor(item.leatherarmor || '');
      setArmorTrim(item.trim || '');
      setCustomData(item.customdata || '');
      setDuplicate(item.duplicate || '');
      setCommands(item.commands || []);
      setRawCommands(item.commands?.join('\n') || '');
      setMultiPaywall(item['multi-paywall'] || []);
      setRawMultiPaywall(item['multi-paywall']?.join('\n') || '');
      setRefreshCommands(item['refresh-commands'] || []);
      setRawRefreshCommands(item['refresh-commands']?.join('\n') || '');
      setItemType(item['itemType'] || []);
      setRawItemType(item['itemType']?.join('\n') || '');
      setBanner(item.banner || []);
      setRawBanner(item.banner?.join('\n') || '');
      setPlayerInput(item['player-input'] || []);
      setRawPlayerInput(item['player-input']?.join('\n') || '');
    } else {
      setMaterial('');
      setName('');
      setLore([]);
      setRawLore('');
      setStack('');
      setDamage('');
      setPotion('');
      setPotionColor('');
      setEnchanted([]);
      setRawEnchanted('');
      setLeatherArmor('');
      setArmorTrim('');
      setCustomData('');
      setDuplicate('');
      setCommands([]);
      setRawCommands('');
      setRefreshCommands([]);
      setRawRefreshCommands('');
      setItemType([]);
      setRawItemType('');
      setMultiPaywall([]);
      setRawMultiPaywall('');
      setBanner([]);
      setRawBanner('');
      setPlayerInput([]);
      setRawPlayerInput('');
    }
  }, [config, currentSlot, currentSection, currentAnimateFrame]);

  const handleInputChange = (e, stateSetter, key) => {
    if (!config?.item) {config.item = [];}
    const value = e.target.value;
    stateSetter(value);
    props.updateInventoryItem(key, value);
  };

  const handleListChange = (e, rawStateSetter, stateSetter, key) => {
    if (!config?.item) {config.item = [];}
    const rawValue = e.target.value;
    rawStateSetter(rawValue);
    stateSetter(rawValue.split('\n'));
    props.updateInventoryItem(key, rawValue.split('\n'));
  };  

  // Delete the item from the configuration
  const handleDeleteItem = () => {
    Swal.fire({
      title: 'Are you sure you want to delete this item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        setConfig((prevConfig) => {
          //JSON.parse and stringify required to make a deep copy so that any copied panels are not affected
          const updatedConfig = JSON.parse(JSON.stringify(prevConfig));
      
          // Check if the slot exists
          if (updatedConfig.item[currentSlot]) {
            const sectionKeys = currentSection ? currentSection.split('.') : [];
            const path = ['item', currentSlot, ...sectionKeys];
      
            if (currentAnimateFrame) {
              path.push(currentAnimateFrame);
            }
      
            const parentPath = path.slice(0, -1);
            const lastKey = path[path.length - 1];
      
            const parentItem = parentPath.reduce((acc, key) => acc?.[key], updatedConfig);
      
            if (parentItem) {
              delete parentItem[lastKey];
            }
      
            return updatedConfig;
          }
      
          return prevConfig;
        });
      }        
    });
  };
  
  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '255px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
  };

  const labelStyle = {
    display: 'inline-block',
    width: '220px',
    marginBottom: '5px',
  };

  const buttonStyle = {
    position: 'absolute',
    width: '100px',
    right: '10px',
    padding: '5px',
  };
  
  const deleteButton = {
    position: 'absolute',
    backgroundColor: 'red',
    width: '100px',
    right: '120px',
    padding: '5px',
  };  

  const textAreaStyle = {
    width: '455px',
    height: '100px',
    fontSize: '12px',
    marginBottom: '5px',
    marginLeft: '10px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
    resize: 'none',
    overflow: 'auto',
    overflowWrap: 'normal',
    whiteSpace: 'pre',
    overflowX: 'scroll',
  };  

  return (
    <div style={{ padding: '3px' }}>

      <button style={buttonStyle} onClick={() => setItemSettingsView(!itemSettingsView)}>
        {itemSettingsView ? "Advanced" : "Basic"}
      </button>
      {config.item?.[currentSlot] && (
        <button style={deleteButton} onClick={handleDeleteItem}>
          {"Delete Item"}
        </button>
      )}
      <div>
      {config.item?.[currentSlot] ? (
        <label style={labelStyle}>Slot {currentSlot}<p>Shift Click another slot to copy this item</p></label>
      ) : (
        <label style={labelStyle}>Slot {currentSlot}<p>No item in this Slot</p></label>
      )}
      </div>

    
      <div className="basic">
        <MaterialSearch textures={props.textures} handleInputChange={handleInputChange} material={material} setMaterial={setMaterial}/>
        <p>
          <label style={labelStyle}>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => handleInputChange(e, setName, 'name')}
            style={inputStyle}
          />
        </p>

        <div>
          <EditableModal
            initialText={rawLore}
            label="Lore:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawLore, setLore, 'lore');}}
          />
          <textarea
            value={rawLore}
            onChange={(e) => handleListChange(e, setRawLore, setLore, 'lore')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawCommands}
            label="Commands:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawCommands, setCommands, 'commands');}}
          />
          <textarea
            value={rawCommands}
            onChange={(e) => handleListChange(e, setRawCommands, setCommands, 'commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
      </div>
      {itemSettingsView && (
      <div className="advanced">
        <hr/>
        <p>
          <label style={labelStyle}>Stack:</label>
          <input
            type="text"
            value={stack}
            onChange={(e) => handleInputChange(e, setStack, 'stack')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Damage:</label>
          <input
            type="text"
            value={damage}
            onChange={(e) => handleInputChange(e, setDamage, 'damage')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion:</label>
          <input
            type="text"
            value={potion}
            onChange={(e) => handleInputChange(e, setPotion, 'potion')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion Color:</label>
          <input
            type="text"
            value={potionColor}
            onChange={(e) => handleInputChange(e, setPotionColor, 'potion-color')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawEnchanted}
            label="Enchanted:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawEnchanted, setEnchanted, 'enchanted');}}
          />
          <textarea
            value={rawEnchanted}
            onChange={(e) => handleListChange(e, setRawEnchanted, setEnchanted, 'enchanted')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <p>
          <label style={labelStyle}>Armor Trim:</label>
          <input
            type="text"
            value={armorTrim}
            onChange={(e) => handleInputChange(e, setArmorTrim, 'trim')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Leather Armor:</label>
          <input
            type="text"
            value={leatherArmor}
            onChange={(e) => handleInputChange(e, setLeatherArmor, 'leatherarmor')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Custom Model Data:</label>
          <input
            type="text"
            value={customData}
            onChange={(e) => handleInputChange(e, setCustomData, 'customdata')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Duplicate:</label>
          <input
            type="text"
            value={duplicate}
            onChange={(e) => handleInputChange(e, setDuplicate, 'duplicate')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawItemType}
            label="Item Type:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawItemType, setItemType, 'itemType');}}
          />
          <textarea
            value={rawItemType}
            onChange={(e) => handleListChange(e, setRawItemType, setItemType, 'itemType')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawPlayerInput}
            label="Player Input:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawPlayerInput, setPlayerInput, 'player-input');}}
          />
          <textarea
            value={rawPlayerInput}
            onChange={(e) => handleListChange(e, setRawPlayerInput, setPlayerInput, 'player-input')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawRefreshCommands}
            label="Refresh Commands:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawRefreshCommands, setRefreshCommands, 'refresh-commands');}}
          />
          <textarea
            value={rawRefreshCommands}
            onChange={(e) => handleListChange(e, setRawRefreshCommands, setRefreshCommands, 'refresh-commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawMultiPaywall}
            label="Multi Paywall:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawMultiPaywall, setMultiPaywall, 'multi-paywall');}}
          />
          <textarea
            value={rawMultiPaywall}
            onChange={(e) => handleListChange(e, setRawMultiPaywall, setMultiPaywall, 'multi-paywall')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawBanner}
            label="Banner:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawBanner, setBanner, 'banner');}}
          />
          <textarea
            value={rawBanner}
            onChange={(e) => handleListChange(e, setRawBanner, setBanner, 'banner')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
      </div>
      )}
    </div>
  );
};