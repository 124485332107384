import React, { useState, useEffect, useRef } from 'react';
import "../../css/util/logicCreator.css";
import "../../css/logic/logic.scss";

const LogicCreator = ({ onChange, config, currentSection, removeIndex, slotPrefix, actualSlot }) => {

  const [conditions, setConditions] = useState([{ value: '', compareType: '', compareValue: '', conditionType: '', logic: 'OR' }]);
  
  //converts the strings to conditions, will only run on updates where it is required to
  useEffect(() => {
    if (config && actualSlot && currentSection) {
      let current = slotPrefix ? config[slotPrefix]?.[actualSlot] : config?.[actualSlot];
      const sectionKeys = currentSection.split(".");
      for (const key of sectionKeys) {
        current = current[key];
      }
  
      const parsedConditions = [];
      let i = 0;
      while (true) {
        const value = `${current[`value${i}`] || ''}`;
        const compare = `${current[`compare${i}`] || ''}`;
  
        if (!value && !compare) {
          break;
        }
  
        const conditionType = value.startsWith("NOT") ? "NOT" : "";
        const strippedValue = conditionType === "NOT" ? value.substring(3).trim() : value;
  
        const compareTypeMatch = strippedValue.match(/(ISGREATER|HASPERM)$/);
        const compareType = compareTypeMatch ? compareTypeMatch[0] : "";
        const finalValue = compareType ? strippedValue.slice(0, -compareType.length).trim() : strippedValue;
  
        const logicMatch = compare.match(/(AND|OR)$/);
        const logic = logicMatch ? logicMatch[0] : "";
        const compareValue = logic ? compare.slice(0, -logic.length).trim() : compare;
  
        parsedConditions.push({ value: finalValue, compareType, compareValue, conditionType, logic });
        i++;
      }
  
      setConditions(parsedConditions);
    }
  }, [currentSection]);

  const handleChange = (index, field, value) => {
    const newConditions = [...conditions];
    newConditions[index][field] = value;
    onChange(newConditions);
  };
  
  //add a new condition locally, this means if nothing is added to it
  //and then it is disregarded, it will be removed which is fine
  const addCondition = () => {
    setConditions([...conditions, { value: '', compareType: '', compareValue: '', conditionType: '', logic: '' }]);
  };
  
  //remove the condition from the local conditions list and the config file
  //if the last condition is empty, it will not remove from config as that will remove the last non empty condition too
  const removeCondition = () => {
    if (conditions.length > 0) {
      const lastCondition = conditions[conditions.length - 1];
      const isEmptyCondition =
        !lastCondition.value && !lastCondition.compareValue;
  
      const newConditions = conditions.slice(0, -1);
      setConditions(newConditions);
  
      if (!isEmptyCondition) {
        removeIndex();
      }
    }
  };

  return (
    <div className="logic-creator">
      {conditions.map((condition, index) => (
        <div key={index} className="condition">
          <select
            value={condition.conditionType}
            onChange={(e) => handleChange(index, 'conditionType', e.target.value)}
          >
            <option value="">If</option>
            <option value="NOT">If Not</option>
          </select>
          <input
            type="text"
            value={condition.value}
            onChange={(e) => handleChange(index, 'value', e.target.value)}
          />
          <select
            value={condition.compareType}
            onChange={(e) => handleChange(index, 'compareType', e.target.value)}
          >
            <option value="">Is Equal To</option>
            <option value="ISGREATER">EqlOrGrtrThan</option>
            <option value="HASPERM">Has Permission</option>
          </select>
          <input
            type="text"
            value={condition.compareValue}
            onChange={(e) => handleChange(index, 'compareValue', e.target.value)}
          />
          {index < conditions.length - 1 && (
            <select
              value={condition.logic || 'OR'}
              onChange={(e) => handleChange(index, 'logic', e.target.value)}
            >
              <option value="OR">OR</option>
              <option value="AND">AND</option>
            </select>
          )}
        </div>
      ))}
      <button onClick={addCondition}>Add Condition</button>
      <button onClick={removeCondition}>Remove Condition</button>
    </div>
  );
};

export default LogicCreator;