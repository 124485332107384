import React, { useState } from "react";
import "../../css/editor/editor.scss";
import DraggableWindow from '../DraggableWindow/Window';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { auth } from "../../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import FileHandler from './FileHandler';
import InventoryGrid from '../EditContents/InventoryGrid';
import HasSections from '../EditContents/HasSections';
import AnimationWindow from '../animations/AnimationWindow';
import CustomItems from '../CustomItems/CustomItemsWindow';
import ItemSettings from '../EditContents/ItemSettings';
import HotbarItemSettings from '../EditContents/HotbarItem';
import CustomTitle from '../CustomTitle/CustomTitle';
import PanelSettings from '../EditContents/PanelSettings';
import LoadingBar from "../util/loadingBar";

function Editor({ fileName, setFileName, fullConfig, setFullConfig, panelName, setPanelName, config, setConfig, textures, isLoading }) {
  const [currentSlot, setCurrentSlot] = useState(0);
  const [currentSection, setCurrentSection] = useState('');
  const [currentCustomItem, setCurrentCustomItem] = useState('');
  const [currentAnimateFrame, setCurrentAnimateFrame] = useState('');

  const [itemSettingsView, setItemSettingsView] = useState(false);
  const [hotbarSettingsView, setHotbarSettingsView] = useState(false);
  const [customTitleView, setCustomTitleView] = useState(false);
  const [customItemsView, setCustomItemsView] = useState(false);
  const [panelSettingsView, setPanelSettingsView] = useState(false);
  const [user] = useAuthState(auth);

  //this can be used to update a property in any config location
  const updateNestedProperty = (obj, path, value) => {
    if (path.length === 1) {
      return { ...obj, [path[0]]: value };
    }
    const [head, ...rest] = path;
    return { ...obj, [head]: updateNestedProperty(obj[head] || {}, rest, value) };
  };

  //window position changes
  const handlePositionChange = (id, x, y) => {
    localStorage.setItem(`${id}X`, x);
    localStorage.setItem(`${id}Y`, y);
  };
  

  //updates an inventory item
  const updateInventoryItem = (key, value) => {
    setConfig((prevConfig) => {
      const updatedConfig = { ...prevConfig };
  
      // Check if the slot exists, create one if not
      if (!updatedConfig.item[currentSlot]) {
        updatedConfig.item[currentSlot] = {};
      }
  
      const sectionKeys = currentSection ? currentSection.split('.') : [];
      const path = ['item', currentSlot, ...sectionKeys];
      
      if (currentAnimateFrame) {
        path.push(currentAnimateFrame);
      }
      
      path.push(key);
  
      return updateNestedProperty(updatedConfig, path, value);
    });
  };  

  //updates root panel settings
  const updatePanelSetting = (key, value) => {
    setConfig(prevConfig => {
      const updatedItem = updateNestedProperty(prevConfig, [key], value);
      return updatedItem;
    });
  };

  //render export/import window first, and don't render others if config is not defined
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="editor-draggable">
        <div className="window-container">
        <DraggableWindow
          id="Panel Slots"
          x={parseInt(localStorage.getItem('PanelSlotsX') || 500)}
          y={parseInt(localStorage.getItem('PanelSlotsY') || 50)}
          width={675}
          height={475}
          onPositionChange={(x, y) => handlePositionChange('PanelSlots', x, y)}>
            <InventoryGrid 
              key={panelName}
              config={config}
              setConfig={setConfig}
              panelName={panelName}
              currentSlot={currentSlot}
              currentAnimateFrame={currentAnimateFrame}
              currentSection={currentSection} 
              handleOnClickSlot={setCurrentSlot} 
              textures={textures} 
              isLoading={isLoading || typeof config === 'undefined'}/>
          </DraggableWindow>
        </div>
        {typeof textures !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Custom Title Settings"
            x={parseInt(localStorage.getItem('CustomTitleSettingsX') || 825)}
            y={parseInt(localStorage.getItem('CustomTitleSettingsY') || 800)}
            width={350}
            height={customTitleView ? 675 : 150}
            onPositionChange={(x, y) => handlePositionChange('CustomTitleSettings', x, y)}>
              <CustomTitle
                config={config}
                setConfig={setConfig}
                currentSection={currentSection}
                setCustomTitleView={setCustomTitleView}
                customTitleView={customTitleView}
                updatePanelSetting={updatePanelSetting}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        {typeof config !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Logic Sections"
            x={parseInt(localStorage.getItem('LogicSectionsX') || 825)}
            y={parseInt(localStorage.getItem('LogicSectionsY') || 525)}
            width={350}
            height={currentSection ? 500 : 275}
            onPositionChange={(x, y) => handlePositionChange('LogicSections', x, y)}>
             <HasSections 
              setConfig={setConfig}
              config={config} 
              currentSlot={"item." + currentSlot}
              panelName={panelName}
              currentSection={currentSection} 
              setCurrentSection={setCurrentSection}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        {typeof config !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Animation Frames"
            x={parseInt(localStorage.getItem('AnimationFramesX') || 500)}
            y={parseInt(localStorage.getItem('AnimationFramesY') || 525)}
            width={325}
            height={275}
            onPositionChange={(x, y) => handlePositionChange('AnimationFrames', x, y)}>
             <AnimationWindow 
              setConfig={setConfig}
              config={config} 
              currentSlot={"item." + currentSlot}
              panelName={panelName} 
              currentSection={currentSection}
              currentAnimateFrame={currentAnimateFrame}
              setCurrentAnimateFrame={setCurrentAnimateFrame}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        <div className="window-container">
        <DraggableWindow
          id="Save Panel"
          x={parseInt(localStorage.getItem('SavePanelX') || 500)}
          y={parseInt(localStorage.getItem('SavePanelY') || 800)}
          minimisable={false}
          width={325}
          height={user ? 325 : 250}
          onPositionChange={(x, y) => handlePositionChange('SavePanel', x, y)}>
          <FileHandler
            config={config}
            fullConfig={fullConfig}
            panelName={panelName}
            fileName={fileName}
            setFileName={setFileName}
            setFullConfig={setFullConfig}
            setConfig={setConfig}
            setPanelName={setPanelName}
          />
          </DraggableWindow>
        </div>
        {typeof textures !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Custom Items"
            x={parseInt(localStorage.getItem('CustomItemsX') || 0)}
            y={parseInt(localStorage.getItem('CustomItemsY') || 725)}
            width={500}
            height={currentCustomItem ? (customItemsView ? 1575 : 625) : 300}
            onPositionChange={(x, y) => handlePositionChange('CustomItems', x, y)}>
              <CustomItems
                textures={textures}
                config={config}
                setConfig={setConfig}
                currentSlot={currentSlot}
                currentCustomItem={currentCustomItem}
                setCurrentCustomItem={setCurrentCustomItem}
                setCustomItemsView={setCustomItemsView}
                customItemsView={customItemsView}
                updatePanelSetting={updatePanelSetting}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        {typeof config !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Panel Settings"
            x={parseInt(localStorage.getItem('PanelSettingsX') || 0)}
            y={parseInt(localStorage.getItem('PanelSettingsY') || 50)}
            width={500}
            height={panelSettingsView ? 1450 : 675}
            onPositionChange={(x, y) => handlePositionChange('PanelSettings', x, y)}>
              <PanelSettings 
                config={config} 
                currentSlot={currentSlot}
                panelSettingsView={panelSettingsView}
                setPanelSettingsView={setPanelSettingsView}
                updatePanelSetting={updatePanelSetting}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        {typeof textures !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Hotbar Item Settings"
            x={parseInt(localStorage.getItem('HotbarItemSettingsX') || 1175)}
            y={parseInt(localStorage.getItem('HotbarItemSettingsY') || 700)}
            width={500}
            height={hotbarSettingsView ? 1900 : 575}
            onPositionChange={(x, y) => handlePositionChange('HotbarItemSettings', x, y)}>
              <HotbarItemSettings
                textures={textures}
                config={config}
                setConfig={setConfig}
                currentSlot={currentSlot}
                currentSection={currentSection}
                setHotbarSettingsView={setHotbarSettingsView}
                hotbarSettingsView={hotbarSettingsView}
                updatePanelSetting={updatePanelSetting}/>
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
        {typeof config !== 'undefined' ? (
          <div className="window-container">
          <DraggableWindow
            id="Item Settings"
            x={parseInt(localStorage.getItem('ItemSettingsX') || 1175)}
            y={parseInt(localStorage.getItem('ItemSettingsY') || 50)}
            width={500}
            height={itemSettingsView ? 1800 : 650}
            onPositionChange={(x, y) => handlePositionChange('ItemSettings', x, y)}>
              <ItemSettings
                textures={textures}
                setConfig={setConfig}
                config={config}
                currentSlot={currentSlot}
                currentSection={currentSection}
                currentAnimateFrame={currentAnimateFrame}
                setItemSettingsView={setItemSettingsView}
                itemSettingsView={itemSettingsView}
                updateInventoryItem={updateInventoryItem} />
            </DraggableWindow>
          </div>
        ) : (
          <LoadingBar text={"Loading..."}/>
        )}
      </div>
    </DndProvider>
  );
}

export default Editor;