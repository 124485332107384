import React, { useState, useEffect } from "react";
import "../../css/util/loadingbar.css";

const LoadingBar = ({text, width = 125, height = 125, addY='500'}) => {
  return  (
    <div className="loading-screen" style={{height: `${addY}px`}}>
      <div className="loading-spinner" style={{width: `${width}px`, height: `${height}px`}}></div>
      <div className="loading-text">{text}</div>
    </div>
  ) 
};

export default LoadingBar;