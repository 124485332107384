import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../css/repository/repo.scss";
import { getRepositories, getPanelIds } from './UploadManager';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase/firebaseConfig";
import { ref, getStorage, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import LoadingBar from '../util/loadingBar';
import Swal from 'sweetalert2';

const Repo = () => {
  const [panels, setPanels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user] = useAuthState(auth);
  const [filter, setFilter] = useState('All Panels');
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetchPanels(currentPage);
  }, [user, filter, currentPage]);

  const fetchPanels = async (page) => {
    try {
      const cacheKey = `panels_cache_${filter}_${page}`;
      const cacheData = localStorage.getItem(cacheKey);
      const now = Date.now();
      let panelsData = [];
      
      if (cacheData && JSON.parse(cacheData).expiry > now) {
        // Use cached data
        panelsData = JSON.parse(cacheData).panels;
      } else {
        // Fetch new data
        const panelIds = await getPanelIds();
        const repositories = await getRepositories(panelIds);
        const panelsFiltered = repositories.filter(repo => (filter === 'My Panels' && user) ? repo.owner === user.uid : true).map(repo => ({
          panelId: repo.panelId,
          username: repo.username,
          panelFile: repo.panelFile,
          imageUrl: repo.imageUrl,
          name: repo.name,
          description: repo.description,
          owner: repo.owner,
          commandPanelsVersion: repo.commandPanelsVersion,
          minecraftVersion: repo.minecraftVersion,
          lastModified: repo.lastModified,
        }));
        panelsData = panelsFiltered;
        
        // Update cache with new data and set expiry date one day later
        localStorage.setItem(cacheKey, JSON.stringify({
          panels: panelsData,
          expiry: now + (3 * 60 * 60 * 1000) // 3 hours in milliseconds
        }));
      }

      panelsData.sort((a, b) => {
        const lastModifiedA = a.lastModified || 0;
        const lastModifiedB = b.lastModified || 0;
      
        // Use the subtraction operator to determine the order in descending order
        return lastModifiedB - lastModifiedA;
      });

      const itemsPerPage = 10;
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      // Return only the panels in the specified page
      setPanels(panelsData.slice(startIndex, endIndex));
    } catch (error) {
      console.error('Error fetching panels:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUniqueFileName = async (storage, userPastePath, fileName) => {
    let i = 1;
    let uniqueFileName = fileName;
  
    while (true) {
      const userPasteRef = ref(storage, `${userPastePath}${uniqueFileName}`);
      try {
        await getDownloadURL(userPasteRef); // If this succeeds, file exists
        uniqueFileName = fileName.replace('.yml', `-${i}.yml`);
        i++;
      } catch (error) {
        if (error.code === 'storage/object-not-found') {
          return uniqueFileName; // Unique file name found
        }
        throw error; // Other errors
      }
    }
  };
  
  //when add to editor button is clicked
  const handlePanelClick = async (panelId, fileName) => {
    if (user && user.uid) {
      try {
        //enable loading screen, so that the button cannot be spammed
        setIsLoading(true);

        const storage = getStorage();
        const repoPath = `repo/${panelId}/`;
        const userPastePath = `pastes/${user.uid}/`;

        // Get the list of items in the user's pastes folder
        const userPastesList = await listAll(ref(storage, userPastePath));
        // Check the count of items in the user's pastes folder
        if (userPastesList.items.length >= 50) {
          // Display a message indicating the user has reached their limit
          await Swal.fire('Cannot copy Panel!', 'You have reached the maximum limit of 50 panels in your folder.', 'error');
          return;
        }
  
        //copy the file to the users profile
        const panelRef = ref(storage, `${repoPath}${fileName}`);
        const downloadURL = await getDownloadURL(panelRef);
        const response = await fetch(downloadURL);
        const panelYAML = await response.text();
  
        const uniqueFileName = await getUniqueFileName(storage, userPastePath, fileName);
        const userPasteRef = ref(storage, `${userPastePath}${uniqueFileName}`);
        const panelBlob = new Blob([panelYAML], { type: 'text/yaml' });
        await uploadBytes(userPasteRef, panelBlob);
  
        //send a message with the file name added and navigate to the profile page
        navigate('/profile');
        await Swal.fire(`File Name: ${uniqueFileName}`, 'The Panel has been saved to your profile.', 'success');
      } catch (error) {
        console.error('Error copying panel to user pastes location:', error);
      }
    } else {
      await Swal.fire('Cannot view Panel!', 'You must be logged in to use this feature.', 'error');
    }
  };

  //when the download button is pressed
  const handleDownloadClick = async (panelId, fileName) => {
    try {
      const storage = getStorage();
      const repoPath = `repo/${panelId}/`;
  
      const panelRef = ref(storage, `${repoPath}${fileName}`);
      const downloadURL = await getDownloadURL(panelRef);
      
      const response = await fetch(downloadURL);
      const blob = await response.blob();
      const blobURL = window.URL.createObjectURL(blob);
  
      const anchorElement = document.createElement('a');
      anchorElement.href = blobURL;
      anchorElement.download = fileName;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading panel:', error);
    }
  };  

  const navigateToUpload = () => {
    if (user && user.uid) {
      navigate('/upload');
    } else {
      Swal.fire('Cannot upload Panel!', 'You must be logged in to use this feature.', 'error');
    }
  };

  const handleModifyClick = (panelId) => {
    navigate(`/upload?panelId=${panelId}`);
  }; 
  
  const toggleFilter = () => {
    if (!user) {
      Swal.fire('Please log in!', 'You must be logged in to view your panels.', 'error');
    } else {
      setCurrentPage(1);
      setFilter(filter === 'All Panels' ? 'My Panels' : 'All Panels');
    }
  };

  //do page number changes
  const navigateToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const navigateToPrevPage = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  //popup for custom page numbers
  const promptForPageNumber = async () => {
    const { value } = await Swal.fire({
      title: 'Enter page number',
      input: 'number',
      inputValue: currentPage,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to provide a page number!';
        }
        if (parseInt(value) < 1) {
          return 'Page number should be 1 or greater!';
        }
      }
    });
  
    if (value) {
      setCurrentPage(parseInt(value));
    }
  };  

  return (
    <div className="repoContainer">
      {isLoading ? (
      <LoadingBar text={"Loading Repository"} />
      ) : (
        <main className="repoContent" style={{ maxWidth: '960px', margin: '0 auto' }}>
          
          <section>
            <div className="headerTop">
              <h1>Repository Guidelines</h1>
              <button onClick={navigateToUpload} className="uploadButton">Upload Panel</button>
            </div>
            <div className="headerContainer">
              <p>Explore the community's CommandPanels panels, find the one you need, and download it with just one click.</p>
              <p>Only upload panels you've created or have permission to share. Respect the original creators' work.</p>
              <p>Do not upload or share panels containing offensive, harmful, or inappropriate content.</p>
              <p>Any panels that do not function as intended, harmful or do not meet guidelines will be removed and need to be reuploaded.</p>
            </div>
          </section>
          <section>
            <div className="headerTop">
              <h1>Panel Uploads</h1>
              <button onClick={toggleFilter} className="uploadButton">{filter}</button>
              <div className="pagination">
                <button onClick={navigateToPrevPage}/>
                <h2 style={{ cursor: 'pointer' }} onClick={promptForPageNumber}>{currentPage}</h2>
                <button onClick={navigateToNextPage}/>
              </div>
            </div>
            <div className="panelGallery">
              {panels.map((panel, index) => (
                <div key={index} className="panelCard">
                  <div className="panelCardImage">
                    <img src={panel.imageUrl} alt={panel.panelId} />
                  </div>
                  <div className="panelCardDescription">
                    <h2>{panel.name}</h2>
                    <h3>{panel.description}</h3>
                  </div>
                  <div className="panelVersions">
                    <p>Tested On CommandPanels: {panel.commandPanelsVersion}</p>
                    <p>Tested On Minecraft: {panel.minecraftVersion}</p>
                    <h4>Author: {panel.username}</h4>
                  </div>
                  <div className="panelCardButtons">
                    <button onClick={() => handlePanelClick(panel.panelId, panel.panelFile)}>Add to Editor</button>
                    {user && user.uid === panel.owner && (
                      <button onClick={() => handleModifyClick(panel.panelId)}>Edit</button>
                    )}
                    <button onClick={() => handleDownloadClick(panel.panelId, panel.panelFile)}>Download</button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      )}
    </div>
  );
};

export default Repo;
