import React, { useState, useEffect } from 'react';
import MaterialSearch from '../EditContents/MaterialSearch.js';
import EditableModal from '../EditContents/EditableModal.js';
import swal from "sweetalert2";

const TreeNode = ({ section, onClick, currentCustomItem }) => {
  return (
    <div
      key={section}
      onClick={() => onClick(section)}
      style={{
        cursor: "pointer",
        fontWeight: currentCustomItem === section ? "bold" : "normal",
      }}
    >
      {section}
    </div>
  );
};

export default function CustomItems(props) {
  const { config, setConfig, currentSlot, setCustomItemsView, customItemsView, currentCustomItem, setCurrentCustomItem } = props;
  const [material, setMaterial] = useState('');
  const [name, setName] = useState('');
  const [lore, setLore] = useState([]);
  const [rawLore, setRawLore] = useState('');
  const [stack, setStack] = useState('');
  const [damage, setDamage] = useState('');
  const [potion, setPotion] = useState('');
  const [potionColor, setPotionColor] = useState('');
  const [enchanted, setEnchanted] = useState([]);
  const [armorTrim, setArmorTrim] = useState('');
  const [itemType, setItemType] = useState([]);
  const [rawItemType, setRawItemType] = useState('');
  const [rawMultiPaywall, setRawMultiPaywall] = useState('');
  const [rawEnchanted, setRawEnchanted] = useState('');
  const [leatherArmor, setLeatherArmor] = useState('');
  const [customData, setCustomData] = useState('');
  const [banner, setBanner] = useState([]);
  const [rawBanner, setRawBanner] = useState('');

  //for a list of the different items in the config file
  const [customItemsList, setCustomItemsList] = useState([]);

  const getItemProperties = (item, currentCustomItem) => {
    if (!currentCustomItem) {
      return item;
    }

    const keys = currentCustomItem.split('.');
    let currentItem = item;
    for (const key of keys) {
      currentItem = currentItem?.[key];
    }
    return currentItem;
  };

  useEffect(() => {
    const item = getItemProperties(config['custom-item'], currentCustomItem);
    if (item) {
      setMaterial(item.material || '');
      setName(item.name || '');
      setLore(item.lore || []);
      setRawLore(item.lore?.join('\n') || '');
      setStack(item.stack || '');
      setDamage(item.damage || '');
      setPotion(item.potion || '');
      setPotionColor(item['potion-color'] || []);
      setItemType(item['itemType'] || []);
      setRawItemType(item['itemType']?.join('\n') || '');
      setEnchanted(Array.isArray(item.enchanted) ? item.enchanted : []);
      setRawEnchanted(Array.isArray(item.enchanted) ? item.enchanted.join('\n') : '');
      setRawMultiPaywall(item['multi-paywall']?.join('\n') || '');
	    setArmorTrim(item.trim || '');
      setLeatherArmor(item.leatherarmor || '');
      setCustomData(item.customdata || '');
      setBanner(item.banner || []);
      setRawBanner(item.banner?.join('\n') || '');
    } else {
      setMaterial('');
      setName('');
      setLore([]);
      setRawLore('');
      setStack('');
      setDamage('');
      setPotion('');
      setPotionColor('');
      setItemType([]);
      setRawItemType('');
      setEnchanted([]);
      setRawEnchanted('');
      setArmorTrim('');
      setLeatherArmor('');
      setCustomData('');
      setRawMultiPaywall('');
      setBanner([]);
      setRawBanner('');
    }
  }, [config, currentCustomItem]);

  useEffect(() => {
    if (config['custom-item']) {
      setCustomItemsList(Object.keys(config['custom-item']));
    }
  }, [config]);

  //changes name to comply with YAML key conventions
  const sanitizeItemName = (name) => {
    return name.replace(/\s+/g, '_').replace(/\./g, '');
  };

  const addCustomItem = async () => {
    const { value: newItemName } = await swal.fire({
      title: 'Enter the name of the new custom item:',
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
    });
  
    if (newItemName) {
      const sanitizedItemName = sanitizeItemName(newItemName);
      if (customItemsList.includes(sanitizedItemName)) {
        await swal.fire({
          title: 'Error',
          text: 'The custom item already exists!',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
        return;
      }
      const newCustomItemsList = [...customItemsList, sanitizedItemName];
      setCustomItemsList(newCustomItemsList);
      const newCustomItems = { ...config['custom-item'], [sanitizedItemName]: {} };
      props.updatePanelSetting('custom-item', newCustomItems);
      setCurrentCustomItem(sanitizedItemName);
    }
  };  
  
  const removeCustomItem = async () => {
    if (currentCustomItem) {
      const result = await swal.fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this custom item!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      if (result.isConfirmed) {
        const newCustomItemsList = customItemsList.filter(item => item !== currentCustomItem);
        setCustomItemsList(newCustomItemsList);
        const newCustomItems = { ...config['custom-item'] };
        delete newCustomItems[currentCustomItem];
        props.updatePanelSetting('custom-item', newCustomItems);
        setCurrentCustomItem(undefined);
      }
    } else{
      await swal.fire({
        title: "Error",
        text: "Please select a custom item to delete.",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
  };

  //helper function for input change handles
  const updateCustomItem = (key, value) => {
    if (currentCustomItem) {
      const currentItem = getItemProperties(config['custom-item'], currentCustomItem);
      const updatedItem = { ...currentItem, [key]: value };
      const updatedConfig = { ...config['custom-item'], [currentCustomItem]: updatedItem };
      props.updatePanelSetting('custom-item', updatedConfig);
    }
  };  

  const handleInputChange = (e, stateSetter, key) => {
    const value = e.target.value;
    stateSetter(value);
    updateCustomItem(key, value);
  };
  
  const handleListChange = (e, rawStateSetter, stateSetter, key) => {
    const rawValue = e.target.value;
    rawStateSetter(rawValue);
    const value = rawValue.split('\n');
    stateSetter(value);
    updateCustomItem(key, value);
  };
  
  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '255px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
  };

  const labelStyle = {
    display: 'inline-block',
    width: '220px',
    marginBottom: '32px',
  };

  const buttonStyle = {
    position: 'absolute',
    width: '100px',
    right: '10px',
    padding: '5px',
  };
  
  const addRemoveButtons = {
    marginLeft: '50px',
  };  

  const textAreaStyle = {
    width: '455px',
    height: '100px',
    fontSize: '12px',
    marginBottom: '10px',
    marginLeft: '10px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
    resize: 'none',
    overflow: 'auto',
    overflowWrap: 'normal',
    whiteSpace: 'pre',
    overflowX: 'scroll',
  };

  return (
    <div style={{ padding: '3px' }}>
      <button style={buttonStyle} onClick={() => setCustomItemsView(!customItemsView)}>
        {customItemsView ? "Advanced" : "Basic"}
      </button>
      <label style={labelStyle}>Custom Items:</label>
      <div className="sectionsList">
        <div
          onClick={() => setCurrentCustomItem(undefined)}
          style={{
            cursor: "pointer",
            fontWeight: !currentCustomItem ? "bold" : "normal",
          }}/>
        {customItemsList.map((itemName) => (
          <TreeNode
          key={itemName}
          section={itemName}
          onClick={() => setCurrentCustomItem(itemName)}
          currentCustomItem={currentCustomItem}
          />
        ))}
      </div>
      <button style={addRemoveButtons} onClick={addCustomItem}>Add Custom Item</button>
      <button style={addRemoveButtons} onClick={removeCustomItem}>Remove Custom Item</button>
      {currentCustomItem && (
      <div className="itemSettings">
      <div className="basic">
        <MaterialSearch textures={props.textures} handleInputChange={handleInputChange} material={material} setMaterial={setMaterial}/>
        <p>
          <label style={labelStyle}>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => handleInputChange(e, setName, 'name')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Custom Model Data:</label>
          <input
            type="text"
            value={customData}
            onChange={(e) => handleInputChange(e, setCustomData, 'customdata')}
            style={inputStyle}
          />
        </p>
      </div>
      {customItemsView && (
      <div className="advanced">
        <hr/>
        <p>
          <EditableModal
            initialText={rawLore}
            label="Lore:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawLore, setLore, 'lore');}}
          />
          <textarea
            value={rawLore}
            onChange={(e) => handleListChange(e, setRawLore, setLore, 'lore')}
            style={textAreaStyle}
            rows={4}
          />
        </p>
        <p>
          <label style={labelStyle}>Stack:</label>
          <input
            type="text"
            value={stack}
            onChange={(e) => handleInputChange(e, setStack, 'stack')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Damage:</label>
          <input
            type="text"
            value={damage}
            onChange={(e) => handleInputChange(e, setDamage, 'damage')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion:</label>
          <input
            type="text"
            value={potion}
            onChange={(e) => handleInputChange(e, setPotion, 'potion')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Potion Color:</label>
          <input
            type="text"
            value={potionColor}
            onChange={(e) => handleInputChange(e, setPotionColor, 'potion-color')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawItemType}
            label="Item Type:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawItemType, setItemType, 'itemType');}}
          />
          <textarea
            value={rawItemType}
            onChange={(e) => handleListChange(e, setRawItemType, setItemType, 'itemType')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <p>
          <EditableModal
            initialText={rawEnchanted}
            label="Enchanted:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawEnchanted, setEnchanted, 'enchanted');}}
          />
          <textarea
            value={rawEnchanted}
            onChange={(e) => handleListChange(e, setRawEnchanted, setEnchanted, 'enchanted')}
            style={textAreaStyle}
            rows={4}
          />
        </p>
        <p>
          <label style={labelStyle}>Armor Trim:</label>
          <input
            type="text"
            value={armorTrim}
            onChange={(e) => handleInputChange(e, setArmorTrim, 'trim')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Leather Armor:</label>
          <input
            type="text"
            value={leatherArmor}
            onChange={(e) => handleInputChange(e, setLeatherArmor, 'leatherarmor')}
            style={inputStyle}
          />
        </p>
        <p>
          <EditableModal
            initialText={rawBanner}
            label="Banner:"
            onChange={(value) => {handleListChange({ target: { value } }, setRawBanner, setBanner, 'banner');}}
          />
          <textarea
            value={rawBanner}
            onChange={(e) => handleListChange(e, setRawBanner, setBanner, 'banner')}
            style={textAreaStyle}
            rows={4}
          />
        </p>
      </div>
      )}
      </div>
      )}
    </div>
  );
};