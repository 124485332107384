import React from "react";
import "../css/notFound.css"; // Import the CSS file for the Minecraft plugin command panel style

const NotFoundPage = () => {
  return (
    <div className="command-panel">
      <div className="command-panel-header">
        <span className="command-panel-title">404 Page Not Found</span>
      </div>
      <div className="command-panel-body">
        <p className="command-panel-text">We couldn't find the page you were looking for.</p>
        <p className="command-panel-text">Please check the URL and try again.</p>
      </div>
    </div>
  );
};

export default NotFoundPage;