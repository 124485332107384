import React from 'react';
import yaml from "js-yaml";
import "../../css/profile/panelList.css";
import { useState, useEffect } from "react";
import { auth } from "../../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaEdit, FaTrash, FaSearch, FaDownload } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { importPanel } from "../Editor/PanelLoader";
import templateYaml from '../Editor/template.yml';
import { getStorage, ref, getDownloadURL, deleteObject } from "firebase/storage";
import Swal from 'sweetalert2';
import { getFileNamesInDirectory, getUserDetails } from '../../firebase/firebaseFunctions';
import LoadingBar from '../util/loadingBar';

export default function PanelList({ setFileName, setFullConfig, setConfig, setPanelName }) {
    const customPopupClasses = {
        createPanel: "createPanel",
        editPanel: "editPanel",
        deletePanel: "deletePanel",
    }

    const navigate = useNavigate();
    const [pasteNames, setPasteNames] = useState([]);
    const [searchedPasteNames, setSearchedPasteNames] = useState([]);

    const [user, loading] = useAuthState(auth);
    const [loadingPastes, setLoadingPastes] = useState(true);

    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        if (loading) {
            return;
        }
        async function getData() {
            try {

                const [fileNames, obtainedUserDetails] = await Promise.all([
                    getFileNamesInDirectory("pastes/" + user.uid),
                    getUserDetails(user.uid)
                ]);
    
                setPasteNames(fileNames);
                setSearchedPasteNames(fileNames);
                setLoadingPastes(false);
                setUserDetails(obtainedUserDetails);
            } catch (error) {
                console.log(error);
            }
        }
        getData();
    }, [user, loading]);

    function handleSearch(event) {
        const searchTerm = event.target.value.toLowerCase();
        const filteredPastes = pasteNames.filter((pasteName) => {
            return pasteName.toLowerCase().includes(searchTerm);
        });
        setSearchedPasteNames(filteredPastes);
    }

    const [reverseSort, setReverseSort] = useState(false);
    const [filterButtonText, setFilterButtonText] = useState("Sorting A-Z");
    function handleFilter() {
        setReverseSort((prevReverseSort) => {
            const newReverseSort = !prevReverseSort;
            setFilterButtonText(newReverseSort ? "Sorting Z-A" : "Sorting A-Z");
            return newReverseSort;
        });
    
        setSearchedPasteNames((prevSearchedPasteNames) => {
            return [...prevSearchedPasteNames].sort((a, b) => {
                return reverseSort ? a.localeCompare(b) : b.localeCompare(a);
            });
        });
    }

    async function deleteFile(fileName) {
        const storageRef = ref(getStorage(), `pastes/${user.uid}/${fileName}`);

        Swal.fire({
            title: `Are you sure you want to delete ${fileName}?`,
            text: 'You will not be able to recover this data!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirm',
            customClass: customPopupClasses.deletePanel,
          }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteObject(storageRef);
                    // Update the state to remove the deleted file from the list
                    setPasteNames((prevPasteNames) => prevPasteNames.filter((name) => name !== fileName));
                    setSearchedPasteNames((prevSearchedPasteNames) =>
                        prevSearchedPasteNames.filter((name) => name !== fileName)
                    );
                } catch (error) {
                    console.log(`Error deleting file ${fileName}: ${error}`);
                }
            }
          });
    }
    
    function createNewPanel() {
        Swal.fire({
            title: `Create a new panel?`,
            text: 'Any unsaved progress on the current panel will be lost!',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: 'green',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Confirm',
            customClass: customPopupClasses.createPanel,
          }).then((result) => {
                if (result.isConfirmed) {
                    fetch(templateYaml)
                    .then((response) => response.text())
                    .then((templateYamlText) => {
                        const loadedTemplate = yaml.safeLoad(templateYamlText);
                        const initialPanelName = Object.keys(loadedTemplate.panels)[0];
                        setFullConfig(loadedTemplate.panels);
                        setPanelName(initialPanelName);
                        setConfig(loadedTemplate.panels[initialPanelName]);
                        setFileName("template");
                        navigate("/editor");
                    })
                }
            }).catch((error) => {
                console.error("Error loading the template config:", error);
            });
    }  

    async function handleEditPanel(pasteName) {
        Swal.fire({
          title: `Edit ${pasteName}?`,
          text: 'Any unsaved progress on the current panel will be lost!',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: 'green',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Confirm',
          customClass: customPopupClasses.editPanel,
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoadingPastes(true);
            const storageRef = ref(getStorage(), `pastes/${user.uid}/${pasteName}`);
            const downloadURL = await getDownloadURL(storageRef);
            const response = await fetch(downloadURL);
            const yamlText = await response.text();
      
            // Remove the file extension from the pasteName
            const fileName = pasteName.split('.').shift();
      
            // Create a new YAML object with the fileName property
            const newConfig = {
              fileName: fileName,
              ...yaml.safeLoad(yamlText),
            };
      
            // Convert the new YAML object to a string and update the state
            const newYamlText = yaml.safeDump(newConfig);
            importPanel(newYamlText, setFileName, setFullConfig, setConfig, setPanelName);
            navigate("/editor");
          }
        }).catch((error) => {
          console.error("Error loading the panel config:", error);
        });
      }   
      
      //download the panel file to the local computer
      async function handleDownloadPanel(pasteName) {
        try {
            const storage = getStorage();
            const storagePath = `pastes/${user.uid}/${pasteName}`;
    
            const panelRef = ref(storage, storagePath);
            const downloadURL = await getDownloadURL(panelRef);
    
            const response = await fetch(downloadURL);
            const blob = await response.blob();
            const blobURL = window.URL.createObjectURL(blob);
    
            const anchorElement = document.createElement('a');
            anchorElement.href = blobURL;
            anchorElement.download = pasteName;
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.error('Error downloading the panel file:', error);
        }
    }    
      
    return (
    user === null ? (
        <div>You must be signed in to view your panels</div>
    ) : loadingPastes ? 
    <LoadingBar text={"Loading Your Panels..."}/> 
    :
    (
        <div className="editPanelContainer">
        <h1>{user.displayName + "'s"} Panel Files ({searchedPasteNames.length}/50)</h1>
        <header className="editPanelHeader">
            <button id="createNewPanelBtn" onClick={createNewPanel}>Create New Panel</button>
            <button id="filterPanelsBtn" onClick={handleFilter}>{filterButtonText}</button>
            <span>
                <FaSearch />
                <input id="search" onChange={handleSearch}/>
            </span>
        </header>
        <div className="panelDashboard">
            <div className="panelList">
                {searchedPasteNames.map((pasteName, index) => {
                    return (
                    <li className="panelItem" key={index}>
                        <ol className="panelItemName">{pasteName}</ol>
                        <ol className="panelItemButtons">
                        <button
                            className="downloadButton"
                            onClick={() => handleDownloadPanel(pasteName)}
                        >
                            <FaDownload />
                        </button>
                        <button
                            className="editButton"
                            onClick={() => handleEditPanel(pasteName)}
                        >
                            <FaEdit />
                        </button>
                        <button className="deleteButton" onClick={() => deleteFile(pasteName)}>
                            <FaTrash />
                        </button>
                        </ol>
                    </li>
                    );
                })}
                </div>
            </div>
        </div>
        )
    );
};
