import React, { useState, useEffect } from "react";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import NotFound from "./components/NotFound";
import Home from "./components/Home";
import Repo from "./components/repository/Repo";
import Upload from "./components/repository/UploadModify";
import Wiki from "./components/Wiki";
import Partner from "./components/Partner";
import Layout from "./Layout";
import PanelList from "./components/Profile/PanelList";
import Editor from "./components/Editor/Editor";
import Downloads from "./components/download/Download";
import ForgotPassword from "./components/auth/ForgotPassword";
import templateYaml from './components/Editor/template.yml';
import yaml from "js-yaml";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import JSZip from "jszip";

function App() {
  const [fullConfig, setFullConfig] = useState({});
  const [fileName, setFileName] = useState("");
  const [panelName, setPanelName] = useState("");
  const [config, setConfig] = useState({});
  const [textures, setTextures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  // raised to here to darken the background when the hamburger menu is open
  const [toggleHamburger, setToggleHamburger] = useState(true);
  const [activeLink, setActiveLink] = useState("/");

  const loadTextures = async () => {
    const cacheName = "textures-cache";
    const cache = await caches.open(cacheName);
    const storage = getStorage();
  
    const zipFileName = "Item_Textures.zip";
    const cacheResponse = await cache.match(zipFileName);
    let zipFileBlob;
  
    if (cacheResponse) {
      zipFileBlob = await cacheResponse.blob();
    } else {
      const storageRef = ref(storage, zipFileName);
      const url = await getDownloadURL(storageRef);
      const zipFileResponse = await fetch(url);
      zipFileBlob = await zipFileResponse.blob();
      await cache.put(zipFileName, new Response(zipFileBlob, { status: 200, statusText: "OK" }));
    }
  
    const zip = await JSZip.loadAsync(zipFileBlob);
    const texturePromises = Object.keys(zip.files).map(async (fileName) => {
      const file = zip.file(fileName);
      const blob = await file.async("blob");
      const url = URL.createObjectURL(blob);
      return { fileName, url };
    });
  
    const loadedTextures = await Promise.all(texturePromises);
    setTextures(loadedTextures);
    setIsLoading(false);
  };

  useEffect(() => {
    // set the active link to the current page when the page is loaded
    setActiveLink(window.location.pathname.split("/")[1]);

    loadTextures();
  
    const storedFullConfig = JSON.parse(localStorage.getItem("fullConfig"));
    const storedFileName = localStorage.getItem("fileName");
  
    if (storedFullConfig && storedFileName) {
      const initialPanelName = Object.keys(storedFullConfig)[0];
      setFullConfig(storedFullConfig);
      setPanelName(initialPanelName);
      setConfig(storedFullConfig[initialPanelName]);
      setFileName(storedFileName);
    } else {
      fetch(templateYaml)
        .then((response) => response.text())
        .then((templateYamlText) => {
          const loadedTemplate = yaml.safeLoad(templateYamlText);
          const initialPanelName = Object.keys(loadedTemplate.panels)[0];
          setFullConfig(loadedTemplate.panels);
          setPanelName(initialPanelName);
          setConfig(loadedTemplate.panels[initialPanelName]);
          setFileName("template");
        })
        .catch((error) => console.error("Error loading the template config:", error));
    }
  }, []);  

  return (
    <div className="appContainer">
      <div className={!toggleHamburger ? "routerContainer active" : "routerContainer"}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout toggleHamburger={toggleHamburger} setToggleHamburger={setToggleHamburger} activeLink={activeLink} setActiveLink={setActiveLink}/>}>
              <Route index element={<Navigate replace to="/home" />} />
              <Route path="/home" element={<Home toggleHamburger={toggleHamburger} />} />
              <Route path="/login" element={<Login setActiveLink={setActiveLink}/>} />
              <Route path="/repository" element={<Repo toggleHamburger={toggleHamburger} setActiveLink={setActiveLink} />} />
              <Route path="/wiki" element={<Wiki toggleHamburger={toggleHamburger} />} />
              <Route path="/partner" element={<Partner />} />
              <Route path="/download" element={<Downloads />} />
              <Route path="/upload" element={<Upload />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgotPassword" element={<ForgotPassword/>} />
              <Route
                path="/profile"
                element={
                  <PanelList
                    setFileName={setFileName}
                    setFullConfig={setFullConfig}
                    setConfig={setConfig}
                    setPanelName={setPanelName}
                  />
                }
              />
              <Route path="/editor" 
                element={
                  <Editor 
                    fileName={fileName} 
                    setFileName={setFileName} 
                    fullConfig={fullConfig} 
                    setFullConfig={setFullConfig} 
                    panelName={panelName} 
                    setPanelName={setPanelName} 
                    config={config} 
                    setConfig={setConfig}
                    textures={textures} 
                    isLoading={isLoading} 
                    setIsLoading={setIsLoading} />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;