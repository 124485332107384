import React from 'react';
import "../css/partner.css";
import partner from '../images/partner_banner.png';

export default function Partner() {
  return (
    <div className="partnerContainer">
      <header className="partnerHeader">
        <div className="partnerLogo">
          <img src={partner} alt="Revivenode Logo" />
        </div>
      </header>
      <div className="partner-content">
      <section className="partnerContentTitle">
        <h1>We have partnered with Revivenode!</h1>
        </section>
        <section className="partnerButton">
            <button><a href="http://billing.Revivenode.com/aff.php?aff=379" target="_blank">Start your Server Now</a></button>
            <h2>Use Promocode: PANELS for 15% off!</h2>
        </section>
        <section className="partnerDescription">
            <h2>Why Choose Revivenode for Minecraft Hosting?</h2>
            <p>
            Revivenode is a leading Minecraft hosting provider that offers affordable and high-quality 
            server hosting solutions. Their focus on performance, reliability, and customer support makes them the perfect partner for CommandPanels.
            </p>
            <h2>Special Offer for CommandPanels Users!</h2>
            <p>
            CommandPanels users get 15% off their first month with Revivenode. We want 
            you to experience their hosting services so you can have a better server management experience.
            </p>
            <p>
            Revivenode premium servers are available at a pocket-friendly price, giving you the 
            power to create your ideal Minecraft community or enjoy playing with friends.
            Their hosting services are high-quality, and affordable, and our customer 
            support is top-notch. The team of experts is available 24/7 to assist you with any 
            questions or concerns you may have on their Discord.
            </p>
            <p>
            After years of personal use, we partnered with Revivenode to provide our users
            with the best possible hosting experience. Join Revivenode today and experience the best Minecraft hosting services on the market!
            </p>
        </section>
      </div>
    </div>
  );
}
