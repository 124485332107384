import React, { useState, useEffect } from 'react';
import EditableModal from './EditableModal';

export default function PanelSettings(props) {
  const { config, setPanelSettingsView, panelSettingsView } = props;
  const [perm, setPerm] = useState('');
  const [rows, setRows] = useState('');
  const [title, setTitle] = useState('');
  const [empty, setEmpty] = useState('');
  const [panelType, setPanelType] = useState([]);
  const [rawPanelType, setRawPanelType] = useState('');
  const [refreshDelay, setRefreshDelay] = useState('');
  const [animateValue, setAnimateValue] = useState('');
  const [commands, setCommands] = useState([]);
  const [rawCommands, setRawCommands] = useState('');
  const [preloadCommands, setPreloadCommands] = useState([]);
  const [rawPreloadCommands, setRawPreloadCommands] = useState('');
  const [commandsOnOpen, setCommandsOnOpen] = useState([]);
  const [rawCommandsOnOpen, setRawCommandsOnOpen] = useState('');
  const [commandsOnClose, setCommandsOnClose] = useState([]);
  const [rawCommandsOnClose, setRawCommandsOnClose] = useState('');
  const [outsideCommands, setOutsideCommands] = useState([]);
  const [rawOutsideCommands, setRawOutsideCommands] = useState('');
  const [disabledWorlds, setDisabledWorlds] = useState([]);
  const [rawDisabledWorlds, setRawDisabledWorlds] = useState('');
  const [enabledWorld, setEnabledWorld] = useState([]);
  const [rawEnabledWorld, setRawEnabledWorld] = useState('');

  useEffect(() => {
    setPerm(config.perm || '');
    setRows(config.rows || '');
    setTitle(config.title || '');
    setEmpty(config.empty || '');
    setCommands(config.commands || []);
    setRawCommands(config.commands?.join('\n') || '');
    setPanelType(config.panelType || []);
    setRawPanelType(config.panelType?.join('\n') || '');
    setRefreshDelay(config['refresh-delay'] || '');
    setAnimateValue(config['animatevalue'] || '');
    setPreloadCommands(config['pre-load-commands'] || []);
    setRawPreloadCommands(config['pre-load-commands']?.join('\n') || '');
    setCommandsOnOpen(config['commands-on-open'] || []);
    setRawCommandsOnOpen(config['commands-on-open']?.join('\n') || '');
    setCommandsOnClose(config['commands-on-close'] || []);
    setRawCommandsOnClose(config['commands-on-close']?.join('\n') || '');
    setOutsideCommands(config['outside-commands'] || []);
    setRawOutsideCommands(config['outside-commands']?.join('\n') || '');
    setDisabledWorlds(config['disabled-worlds'] || []);
    setRawDisabledWorlds(config['disabled-worlds']?.join('\n') || '');
    setEnabledWorld(config['enabled-world'] || []);
    setRawEnabledWorld(config['enabled-world']?.join('\n') || '');
  }, [config]);

  const handleInputChange = (e, stateSetter, key) => {
    const value = e.target.value;
    stateSetter(value);
    props.updatePanelSetting(key, value);
  };

  const handleListChange = (e, rawStateSetter, stateSetter, key) => {
    const rawValue = e.target.value;
    rawStateSetter(rawValue);
    stateSetter(rawValue.split('\n'));
    props.updatePanelSetting(key, rawValue.split('\n'));
  };

  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '255px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
  };

  const labelStyle = {
    display: 'inline-block',
    width: '220px',
    marginBottom: '10px',
  };

  const textAreaStyle = {
    width: '455px',
    height: '100px',
    fontSize: '12px',
    marginBottom: '5px',
    marginLeft: '10px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
    resize: 'none',
    overflow: 'auto',
    overflowWrap: 'normal',
    whiteSpace: 'pre',
    overflowX: 'scroll',
  };

  const buttonStyle = {
    display: 'inline-block',
    width: '100px',
    right: '10px',
    padding: '5px',
  };  

  return (
    <div style={{ padding: '3px'}}>
      <button style={buttonStyle} onClick={() => setPanelSettingsView(!panelSettingsView)}>
        {panelSettingsView ? "Advanced" : "Basic"}
      </button>
      <div className="basic">
        <p>
          <label style={labelStyle}>Perm:</label>
          <input
            type="text"
            value={perm}
            onChange={(e) => handleInputChange(e, setPerm, 'perm')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Rows:</label>
          <input
            type="text"
            value={rows}
            onChange={(e) => handleInputChange(e, setRows, 'rows')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => handleInputChange(e, setTitle, 'title')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Empty:</label>
          <input
            type="text"
            value={empty}
            onChange={(e) => handleInputChange(e, setEmpty, 'empty')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawCommands}
            label="Commands To Open:"
            onChange={(value) => handleListChange({ target: { value } }, setRawCommands, setCommands, 'commands')}
          />
          <textarea
            value={rawCommands}
            onChange={(e) => handleListChange(e, setRawCommands, setCommands, 'commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawCommandsOnOpen}
            label="Commands On Open:"
            onChange={(value) => handleListChange({ target: { value } }, setRawCommandsOnOpen, setCommandsOnOpen, 'commands-on-open')}
          />
          <textarea
            value={rawCommandsOnOpen}
            onChange={(e) => handleListChange(e, setRawCommandsOnOpen, setCommandsOnOpen, 'commands-on-open')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawCommandsOnClose}
            label="Commands On Close:"
            onChange={(value) => handleListChange({ target: { value } }, setRawCommandsOnClose, setCommandsOnClose, 'commands-on-close')}
          />
          <textarea
            value={rawCommandsOnClose}
            onChange={(e) => handleListChange(e, setRawCommandsOnClose, setCommandsOnClose, 'commands-on-close')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
      </div>
      {panelSettingsView && (
      <div className="advanced">
        <hr/>
        <p>
          <label style={labelStyle}>Refresh Delay:</label>
          <input
            type="text"
            value={refreshDelay}
            onChange={(e) => handleInputChange(e, setRefreshDelay, 'refresh-delay')}
            style={inputStyle}
          />
        </p>
        <p>
          <label style={labelStyle}>Animation Frames:</label>
          <input
            type="text"
            value={animateValue}
            onChange={(e) => handleInputChange(e, setAnimateValue, 'animatevalue')}
            style={inputStyle}
          />
        </p>
        <div>
          <EditableModal
            initialText={rawPanelType}
            label="Panel Type:"
            onChange={(value) => handleListChange({ target: { value } }, setRawPanelType, setPanelType, 'panelType')}
          />
          <textarea
            value={rawPanelType}
            onChange={(e) => handleListChange(e, setRawPanelType, setPanelType, 'panelType')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawPreloadCommands}
            label="Pre-load Commands:"
            onChange={(value) => handleListChange({ target: { value } }, setRawPreloadCommands, setPreloadCommands, 'pre-load-commands')}
          />
          <textarea
            value={rawPreloadCommands}
            onChange={(e) => handleListChange(e, setRawPreloadCommands, setPreloadCommands, 'pre-load-commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawOutsideCommands}
            label="Outside Commands:"
            onChange={(value) => handleListChange({ target: { value } }, setRawOutsideCommands, setOutsideCommands, 'outside-commands')}
          />
          <textarea
            value={rawOutsideCommands}
            onChange={(e) => handleListChange(e, setRawOutsideCommands, setOutsideCommands, 'outside-commands')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawDisabledWorlds}
            label="Disabled Worlds:"
            onChange={(value) => handleListChange({ target: { value } }, setRawDisabledWorlds, setDisabledWorlds, 'disabled-worlds')}
          />
          <textarea
            value={rawDisabledWorlds}
            onChange={(e) => handleListChange(e, setRawDisabledWorlds, setDisabledWorlds, 'disabled-worlds')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
        <div>
          <EditableModal
            initialText={rawEnabledWorld}
            label="Enabled Worlds:"
            onChange={(value) => handleListChange({ target: { value } }, setRawEnabledWorld, setEnabledWorld, 'enabled-world')}
          />
          <textarea
            value={rawEnabledWorld}
            onChange={(e) => handleListChange(e, setRawEnabledWorld, setEnabledWorld, 'enabled-world')}
            style={textAreaStyle}
            rows={4}
          />
        </div>
      </div>
      )}
    </div>
  );
};