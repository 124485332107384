import React, { useState, useEffect } from 'react';
import "../../css/download/download.css";

export default function Downloads() {
    const [releases, setReleases] = useState([]);

    useEffect(() => {
        const fetchReleases = async () => {
          try {
            const response = await fetch(`https://api.github.com/repos/rockyhawk64/CommandPanels/releases`);
            if (!response.ok) {
              throw new Error('Could not fetch GitHub releases');
            }
            const data = await response.json();
            console.log(data.map(release => release.body));
            setReleases(data);
          } catch (error) {
            console.error('Error fetching releases:', error);
          }
        };
    
        fetchReleases();
    }, []);

    const markdownRemove = (markdown) => {
        if (!markdown) return "";
      
        // Remove markdown notations
        const bold = markdown.replace(/\*\*(.*?)\*\*/g, '$1');
        const boldAndItalic = bold.replace(/\*(.*?)\*/g, '$1');
      
        // Shorten long words/URLs (more than 32 characters)
        const shortened = boldAndItalic.replace(/([^\s]{33,})/g, (match) => {
            return `${match.slice(0, 29)}...`;
        });

        return shortened;
      }

    // Helper function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };
      

    const generateDownloadURL = (tagName) => `https://github.com/rockyhawk64/CommandPanels/releases/download/${tagName}/CommandPanels.jar`;

    return (
        <div className="downloadsContainer">
            <header>
                <h1>Download CommandPanels</h1>
                <h2>The latest version is recommended for the best experience.</h2>
            </header>
            <div className="downloadButtonsContainer">
                {releases.map((release, index) => (
                    <div className="downloadItem" key={release.id}>
                        <div className="downloadTitle">
                            {`Release ${release.tag_name}`}
                            <div className="downloadSubtitle">
                                {index === 0 ? 'Latest Version - ' : ''}
                                <span>{formatDate(release.published_at)}</span>
                            </div>
                            <div className="downloadDescription">
                                {markdownRemove(release.body).split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div>
                            <a href={generateDownloadURL(release.tag_name)} target="_blank" rel="noopener noreferrer">
                                <button className="downloadsPageButton">
                                    Download
                                    <span>v{release.tag_name}</span>
                                </button>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
