import React, { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase/firebaseConfig';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import '../navbar.scss';
import logo from '../images/faviconBlank.png';
import partner from '../images/partner_small.png';
import discord from '../images/discord.png';
import github from '../images/github.png';
import Popup from 'reactjs-popup';

export default function NavbarComponent({ setToggleHamburger, toggleHamburger, activeLink, setActiveLink }) {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isColourPopupOpen, setIsColourPopupOpen] = useState(false);

  const openPopup = () => {
    if (!auth.currentUser) {
      redirectToPage('login');
    } else {
      setIsPopupOpen(true);
    }
  };

  const redirectToPage = (link) => {
    setActiveLink(link);
    if (link === "profile") {
      setTimeout(() => {
        navigate(link);
      }, 100);
    } else {
      navigate(link);
    }
    navigate(link);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      redirectToPage('home');
    });
  };

  const handleToggleHamburger = () => {
    setToggleHamburger(!toggleHamburger);
  };

  const profileNavItem = () => {
    if (!user?.displayName) {
      return (
        <a onClick={openPopup} className="navbar-link">
          <h3>Login</h3>
        </a>
      );
    }

    return popup;
  };

  window.addEventListener('scroll', function() {
    setIsPopupOpen(false);
  });  

  const popup = (
    <Popup
      trigger={<a className="navbar-link"><h3>{"My Account"}</h3></a>}
      open={isPopupOpen}
      onOpen={openPopup}
      onClose={closePopup}
      contentStyle={{
      position: 'absolute',
      top: '54.5px',
      left: '1065.34px',
      zIndex: 999
    }}
    >
        <div>
          <Link
            onClick={() => {
              redirectToPage('profile');
              handleToggleHamburger();
              closePopup();
            }}
            className="navbar-link"
          >
            <div className="popupElement">
              <h3>View Panels</h3>
            </div>
          </Link>
          <Link
            onClick={() => {
              closePopup();
              handleLogout();
              handleToggleHamburger();
            }}
            className="navbar-link"
          >
            <div className="popupElement">
              <h3 id="logout">Logout</h3>
            </div>
          </Link>
        </div>
    </Popup>
  );

  const colourPopup = (
    <div className="navbar-colour-popup">
      <h4 style={{ color: '#000000' }}>&0 - Black</h4>
      <h4 style={{ color: '#0000AA' }}>&1 - Dark Blue</h4>
      <h4 style={{ color: '#00AA00' }}>&2 - Dark Green</h4>
      <h4 style={{ color: '#00AAAA' }}>&3 - Dark Aqua</h4>
      <h4 style={{ color: '#AA0000' }}>&4 - Dark Red</h4>
      <h4 style={{ color: '#AA00AA' }}>&5 - Dark Purple</h4>
      <h4 style={{ color: '#FFAA00' }}>&6 - Gold</h4>
      <h4 style={{ color: '#AAAAAA' }}>&7 - Gray</h4>
      <h4 style={{ color: '#555555' }}>&8 - Dark Gray</h4>
      <h4 style={{ color: '#5555FF' }}>&9 - Blue</h4>
      <h4 style={{ color: '#55FF55' }}>&a - Green</h4>
      <h4 style={{ color: '#55FFFF' }}>&b - Aqua</h4>
      <h4 style={{ color: '#FF5555' }}>&c - Red</h4>
      <h4 style={{ color: '#FF55FF' }}>&d - Light Purple</h4>
      <h4 style={{ color: '#FFFF55' }}>&e - Yellow</h4>
      <h4 style={{ color: '#FFFFFF' }}>&f - White</h4>
      <h4>&n - <span style={{ textDecoration: 'underline' }}>Underline</span></h4>
      <h4>&l - <span style={{ fontWeight: '600' }}>Bold</span></h4>
      <h4>&o - <span style={{ fontStyle: 'italic' }}>Italic</span></h4>
      <h4>&m - <span style={{ textDecoration: 'line-through' }}>Strikethrough</span></h4>
      <h4>&k - Obfuscated</h4>
      <h4>&r - Reset</h4>
    </div>
  );

  return (
    <nav className={!toggleHamburger ? 'navbar active' : 'navbar'}>
      <img 
        onClick={() => redirectToPage('home')}
        onMouseEnter={() => {
          if (activeLink !== 'editor') return;
          setIsColourPopupOpen(prevState => !prevState);
        }}
        src={logo} 
        alt="logo" 
        className={`navbar-logo ${isColourPopupOpen && activeLink === 'editor' ? 'navbar-logo-animate' : ''}`}/>
      {isColourPopupOpen && activeLink === 'editor' && colourPopup}
      <img onClick={() => redirectToPage('partner')} src={partner} alt="partner" className="partner-favicon" />
      <h2 onClick={() => redirectToPage('partner')}>
        Revivenode 15% Off
        <p>Proud partners of Revivenode</p>
      </h2>
      <div className={toggleHamburger ? 'navbar-toggle' : 'navbar-toggle active'} onClick={handleToggleHamburger}>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </div>
      <ul className={toggleHamburger ? 'navbar-list' : 'navbar-list active'}>
        <li className={activeLink === 'download' ? 'active-link navbar-item' : 'navbar-item'} onClick={() => redirectToPage('download')}>
          <Link onClick={handleToggleHamburger} to="/download" className="navbar-link">
            <h3>Download</h3>
          </Link>
        </li>
        <li className={activeLink === 'editor' ? 'active-link navbar-item' : 'navbar-item'} onClick={() => redirectToPage('editor')}>
          <Link onClick={handleToggleHamburger} to="/editor" className="navbar-link">
            <h3>Editor</h3>
          </Link>
        </li>
        <li className={activeLink === 'repository' ? 'active-link navbar-item' : 'navbar-item'} onClick={() => redirectToPage('repository')}>
          <Link onClick={handleToggleHamburger} to="/repository" className="navbar-link">
            <h3>Panels</h3>
          </Link>
        </li>
        <li className={activeLink === 'wiki' ? 'active-link navbar-item' : 'navbar-item'} onClick={() => redirectToPage('wiki')}>
          <Link onClick={handleToggleHamburger} to="/wiki" className="navbar-link">
            <h3>Wiki</h3>
          </Link>
        </li>
        <li className={activeLink === 'github' ? 'active-link navbar-item' : 'navbar-item'}>
          <Link onClick={handleToggleHamburger} to="https://github.com/rockyhawk64/CommandPanels" target="_blank" className="navbar-link">
            <img src={github} alt="github" className="navbar-image" />
          </Link>
        </li>
        <li className={activeLink === 'discord' ? 'active-link navbar-item' : 'navbar-item'}>
          <Link onClick={handleToggleHamburger} to="https://discord.gg/eUWBWh7" target="_blank" className="navbar-link">
            <img src={discord} alt="discord" className="navbar-image" />
          </Link>
        </li>
        <li className={['login', 'profile'].includes(activeLink) ? 'navbar-item active-link welcome-item' : 'navbar-item welcome-item'}>
          {profileNavItem()}
        </li>
      </ul>
    </nav>
  );
}