import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import "../../css/editor/materialSearch.css";
import LoadingBar from '../util/loadingBar';

export default function MaterialSearch(props) {

    const inputRef = useRef(null);

    function handleSelectedItem(image) {
        // filter out .png or .jpg
        const materialName = image.fileName.split('.')[0];

        return () => {
            inputRef.current.value = materialName;
            props.handleInputChange({ target: { value: materialName } }, props.setMaterial, 'material');
        }
    }

    const inputStyle = {
        textAlign: 'left',
        background: 'white',
        fontSize: '12px',
        fontWeight: 'bold',
        width: '255px',
        height: '22px',
        backgroundColor: '#2b4f65',
        borderColor: '#1e2126',
        color: 'white',
      };
    
      const labelStyle = {
        display: 'inline-block',
        width: '220px',
        marginBottom: '5px',
      };

    const [searchedImages, setSearchedImages] = useState(props.textures);

    useEffect(() => {
        setSearchedImages(props.textures);
        handleImageChange(props.material);
    }, [props.textures]);

    async function handleImageChange(searchText) {
        // lets turn this into a bunch of promises to improve performance
        const filteredTextures = props.textures.filter((texture) => {
            return texture.fileName.includes(searchText.toLowerCase());
        });
        setSearchedImages(filteredTextures);
    }

  return (
    <div>
        <label style={labelStyle}>Material:</label>
        <input ref={inputRef}
        type="text"
        value={props.material}
        onChange={(e) => { 
            handleImageChange(e.target.value);
            props.setMaterial(e.target.value);
            props.handleInputChange(e, props.setMaterial, 'material');
        }}
        style={inputStyle}
        />
        <div className="materialSearchContainer">
            <div className={'materialSearchGrid'}>
            {
                props.textures.length !== 0
                ? searchedImages.map((image) => {
                    return (
                        image && (
                        <div className="imageWrapper" key={image.fileName} onClick={handleSelectedItem(image)}>
                            <img className="materialSearchImage" alt="img" src={image.url} />
                        </div>
                        )
                    );
                    })
                : <LoadingBar text="" width={35} height={35} addY={400}/>
            }
            </div>
        </div>
    </div>
  )
}
