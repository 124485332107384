import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import '../../css/editor/Window.css';
import "../EditContents/InventoryItem";
import "../EditContents/InventoryGrid";

const DraggableWindow = (props) => {
  const [isMinimized, setIsMinimized] = useState(false);
  
  //minimisable is set to true if no value is provided
  const isMinimisable = props.minimisable ?? true;
  const [position, setPosition] = useState({ x: props.x, y: props.y });

  useEffect(() => {
    let x = position.x;
    let y = position.y;
    //only runs setPosition if out of border and will only change x or y, whichever is required
    if (x < 0 || y < 50){
      if (x < 0) x = 0;
      if (y < 50) y = 50; // 50 is the height of the navbar
      setPosition({ x, y });
    }
  }, [position]);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className="grid-container">
      <Draggable
        handle=".window-header"
        grid={[25, 25]}
        position={position}
        onStop={(e, data) => {
          setPosition({ x: data.x, y: data.y });
          props.onPositionChange && props.onPositionChange(data.x, data.y);
        }}
      >
        <div
          className="window"
          style={{
            width: `${props.width}px`,
            height: isMinimized ? '25px' : `${props.height}px`,
            transition: 'height 0.1s ease-in-out',
          }}
        >
          <div className="window-header">
            <div>{props.id}</div>
            {isMinimisable ? (
              <button onClick={handleMinimize}>{isMinimized ? '+' : '--'}</button>
            ) : null}
          </div>
          {!isMinimized && <div className="window-content">{props.children}</div>}
        </div>
      </Draggable>
    </div>
  );
};

export default DraggableWindow;
