import Modal from 'react-modal';
import { useState, useEffect, useLayoutEffect } from 'react';
import "../../css/editor/filehandler.css";
import { auth } from "../../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import yaml from 'js-yaml';
import { exportPanel, importPanel, removeEmptyObjects } from "./PanelLoader";
import LoadingBar from '../util/loadingBar';
import Swal from 'sweetalert2';

const FileHandler = ({
    config,
    fullConfig,
    panelName,
    fileName,
    setConfig,
    setFileName,
    setFullConfig,
    setPanelName,
  }) => {
    const [user] = useAuthState(auth);
  
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'fileName') {
            setFileName(value.replace(/\s+/g, '_'));
        }
        if (name === 'panelName') {
            setPanelName(value);
        }
  };

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  //update full config with the new panel changes
  useEffect(() => {
    if (panelName && config) {
      setFullConfig((prevFullConfig) => ({
        ...prevFullConfig,
        [panelName]: config,
      }));
    }
  }, [config, panelName, setFullConfig]);
  
  //used to save config files to cache
  useEffect(() => {
    if (panelName && config) {
      localStorage.setItem("fileName", fileName);
      localStorage.setItem("fullConfig", JSON.stringify(fullConfig));
    }
  }, [fileName, fullConfig, panelName, config]);

  // when a new panel is selected in the drop down
  useLayoutEffect(() => {
    if (fullConfig && panelName && fullConfig[panelName]) {
      setConfig(fullConfig[panelName]);
    }
  }, [panelName, fullConfig, setConfig]);

      // Handle panel change in the dropdown
    const handlePanelChange = (e) => {
        setPanelName(e.target.value);
    };
    
    // Change the panel name
    const handleChangePanelName = () => {
      Swal.fire({
        title: 'Current panel name:',
        input: 'text',
        inputValue: panelName,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter a name!';
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const newNameWithoutSpaces = result.value.replace(/\s+/g, '');
          if (newNameWithoutSpaces !== panelName) {
            const updatedConfig = { ...fullConfig };
            updatedConfig[newNameWithoutSpaces] = updatedConfig[panelName];
            delete updatedConfig[panelName];
            setFullConfig(updatedConfig);
            setPanelName(newNameWithoutSpaces);
          }
        }
      });
    };

    // Delete the panel from the full configuration
    const handleDeletePanel = () => {
      if (Object.keys(fullConfig).length > 1) {
        Swal.fire({
          title: 'Are you sure you want to delete this panel?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
        }).then((result) => {
          if (result.isConfirmed) {
            const updatedConfig = { ...fullConfig };
            delete updatedConfig[panelName];
            const newPanelName = Object.keys(updatedConfig)[0];
            setFullConfig(updatedConfig);
            setPanelName(newPanelName);
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'You cannot delete the last remaining panel.',
        });
      }
    };    
        
// Combined function for adding and duplicating panels
const handleAddOrDuplicatePanel = (duplicate = false) => {
  Swal.fire({
    title: duplicate ? 'Choose a name for the duplicate panel:' : 'Enter a name for the new panel:',
    input: 'text',
    showCancelButton: true,
    inputValidator: (value) => {
      if (!value) {
        return 'You need to enter a name!';
      }
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const newPanelName = result.value.replace(/\s+/g, '');
      // Check if the new panel name already exists
      if (fullConfig.hasOwnProperty(newPanelName)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'A panel with that name already exists.',
        });
      } else {
        const newPanelConfig = duplicate ? { ...fullConfig[panelName] } : {
          perm: "default",
          rows: "6",
          title: "&8New Panel",
          empty: "AIR",
          item: {}
        };
        setFullConfig((prevFullConfig) => ({ ...prevFullConfig, [newPanelName]: newPanelConfig }));
        setPanelName(newPanelName);
      }
    }
  });
};
    

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoLoginModalOpen, setIsNoLoginModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [copiedText, setCopiedText] = useState(null);
  const [textToCopy, setTextToCopy] = useState("");

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openNoLoginModel = () => {
    setIsNoLoginModalOpen(true);
  };
  const closeNoLoginModal = () => {
    setIsNoLoginModalOpen(false);
  };
  //used because normally it is formatted during export but needs to be formatted still if not logged in
  const getFormattedExport = () => {
    return removeEmptyObjects({ panels: fullConfig });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText("Copied!");
  };
  
  const handleExportClick = async () => {
    setIsLoading(true);
    setCopiedText(null); //reset copied message
    if (!user) {
      setIsLoading(false);
      openNoLoginModel();
      return;
    }
    const generatedText = await exportPanel(user, fileName, fullConfig, panelName, config);
    setTextToCopy(generatedText);
    setIsLoading(false);
    openModal();
  };  

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [importedText, setImportedText] = useState("");

  const openImportModal = () => {
    setIsImportModalOpen(true);
  };
  
  const closeImportModal = () => {
    setIsImportModalOpen(false);
  };
  
  const handleImportProceed = async () => {
    setIsImportModalOpen(false);
    await importPanel(importedText, setFileName, setFullConfig, setConfig, setPanelName);
  };
  
  function handleImportClick() {
    setImportedText("");
    openImportModal();
  }

  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '13px',
    fontWeight: 'bold',
    width: '155px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
    marginBottom: '0px',
  };
  
  const labelStyle = {
    display: 'inline-block',
    width: '130px',
    fontSize: '18px',
  };

  const deleteButtonStyle = {
    backgroundColor: 'red',
  };
  
  const saveButtonStyle = {
    backgroundColor: 'green',
  };
  
  const extensionStyle = {
    fontSize: '18px',
    marginLeft: '5px',
  };
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isLoading ? (
        <LoadingBar addY="350" text={"Processing..."} />
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={handleImportClick} className="buttonStyle buttonStyleHover">Import Panel</button>
            <button onClick={handleExportClick} className="buttonStyle buttonStyleHover" style={saveButtonStyle}>Save Panel</button>
          </div>
          <div style={{ padding: '12px' }}>
            <label style={labelStyle}>Editing:</label>
            <select value={panelName} onChange={handlePanelChange} style={inputStyle}>
              {Object.keys(fullConfig).map(panel => (
                <option key={panel} value={panel}>{panel}</option>
              ))}
            </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button onClick={handleChangePanelName} className="buttonStyle buttonStyleHover">Panel Name</button>
            <button onClick={() => handleAddOrDuplicatePanel(false)} className="buttonStyle buttonStyleHover">Add Panel</button>
            <button onClick={() => handleAddOrDuplicatePanel(true)} className="buttonStyle buttonStyleHover">Copy Panel</button>
            <button onClick={handleDeletePanel} className="buttonStyle buttonStyleHover" style={deleteButtonStyle}>Delete Panel</button>
          </div>
          {/* panel file name is not necessary if not logged in */}
          {user ? (
            <div style={{ display: 'flex', alignItems: 'center', padding: '15px' }}>
              <label style={labelStyle}>Export Name:</label>
              <input name="fileName" type="text" value={fileName} onChange={handleInputChange} style={inputStyle} />
              <span style={extensionStyle}>.yml</span>
            </div>
          ) : null}
        </div>
      )}
      <Modal //export panel modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Copy Link Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Panel has been saved to profile.</h2>
        <h3 style={{color: 'white'}}>Command to import panel:</h3>
        <input
          type="text"
          value={textToCopy}
          readOnly
          onClick={() => copyToClipboard(textToCopy)}
        />
        {copiedText && <p>{copiedText}</p>}
        <br/>
        <hr/>
        <h3 style={{color: 'white'}}>Raw panel YAML:</h3>
        <textarea
          type="text"
          style={{ width: "100%", height: "200px", borderRadius: "5px" }}
          value={yaml.dump(getFormattedExport())}
          readOnly
          onClick={() => copyToClipboard(yaml.dump(getFormattedExport()))}
        />
        <button onClick={closeModal}>Close</button>
      </Modal>
      <Modal //export panel when not logged in modal
        isOpen={isNoLoginModalOpen}
        onRequestClose={closeNoLoginModal}
        contentLabel="Raw YAML Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Export Raw Panel YAML:</h2>
        <textarea
          type="text"
          style={{ width: "100%", height: "200px", borderRadius: "5px" }}
          value={yaml.dump(getFormattedExport())}
          readOnly
          onClick={() => copyToClipboard(yaml.dump(getFormattedExport()))}
        />
        {copiedText && <p>{copiedText}</p>}
        <button onClick={closeNoLoginModal}>Close</button>
      </Modal>
      <Modal //import panel modal
        isOpen={isImportModalOpen}
        onRequestClose={closeImportModal}
        contentLabel="Import Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Paste your Panel clipboard below:</h2>
        <textarea
          style={{ width: "100%", height: "200px" }}
          value={importedText}
          onChange={(e) => setImportedText(e.target.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
          <button onClick={closeImportModal}>Cancel</button>
          <button onClick={handleImportProceed}>Proceed</button>
        </div>
      </Modal>
    </div>
  );
};

export default FileHandler;