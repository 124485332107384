import {
  GoogleAuthProvider,
  getAuth,
  updateProfile,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore";

import {db, auth} from "./firebaseConfig";

// sign in with google
const signInWithGoogle = async () => {
  const googleProvider = new GoogleAuthProvider();
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    // Directly reference the user's document using their UID
    const userDocRef = doc(db, "users", user.uid);

    // Attempt to get the user's document
    const docSnap = await getDoc(userDocRef);

    // If the user's document does not exist, create it
    if (!docSnap.exists()) {
      await setDoc(userDocRef, {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid, // auth.currentUser.uid would also work here
        date: new Date(),
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    //create user
    const res = await createUserWithEmailAndPassword(auth, email, password);
    
    // set auth user display name
    await updateProfile(auth.currentUser, {
      displayName: name,
    }).catch(
      (err) => console.log(err)
    );

    // Write the data to the user collection in firestore
    await setDoc(doc(db, "users", auth.currentUser.uid), {
      displayName: name,
      email: email,
      uid: auth.currentUser.uid,
      date: new Date(),
    });
    
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    //do not throw an error to the user
  }
};

const logout = () => {
  signOut(auth);
};

export {
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};