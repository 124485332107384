import { db, auth } from './firebaseConfig';
import {
  setDoc,
} from "firebase/firestore";
import { collection, getDoc, getDocs, query, limit, where, addDoc, serverTimestamp, doc, updateDoc, increment, arrayUnion, arrayRemove, orderBy, startAfter, deleteDoc, collectionGroup } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage, ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";

// get the user with the specified id from the firestore database 
async function getUserDetails(uid) {
  const docRef = doc(db, "users", uid);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
} 

async function getFileNamesInDirectory(directoryPath) {
  const storageRef = ref(getStorage(), directoryPath);
  try {
    const listResult = await listAll(storageRef);
    const fileNames = listResult.items.map((item) => item.name);
    return fileNames;
  } catch (error) {
    console.log(`Error getting files in directory ${directoryPath}: ${error}`);
    return [];
  }
}

export {getUserDetails, getFileNamesInDirectory};