import React from 'react';
import {useEffect, useRef} from 'react';
import "../../css/auth/login.scss";
import { FcGoogle } from "react-icons/fc";
import { auth } from "../../firebase/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { logInWithEmailAndPassword, signInWithGoogle } from '../../firebase/firebase';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/faviconBlank.png';
import Swal from 'sweetalert2';

export default function Login({setActiveLink}) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  async function handleLoginWithEmailAndPassword(e) {
    e.preventDefault();
    if (!isValidLogin()) {
      Swal.fire('Error', 'You must enter an email and password!', 'error');
      return;
    }
    try {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      await logInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      Swal.fire('Error', err.message, 'error');
    }
  }

  async function handleGoogleSignIn() {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
      Swal.fire('Error', err.message, 'error');
    }
  }

  function isValidLogin() {
    return (emailRef.current.value !== "" || passwordRef.current.value !== "");
  }

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      setActiveLink("home");
      navigate("/home");
    }
  }, [user, loading]);

  return (
    loading 
    ? <h1>Loading...</h1> 
    :<div className="authContainer">
      <div id="loginContainer" className="container">
            <header>
              <img src={logo} alt="logo" className="login_logo"/>
            </header>
            <div className="body">
              <form>
                <label htmlFor="email">Email:</label>
                <input ref={emailRef} type="email" name="email" id="email" required/>

                <label htmlFor="password">Password:</label>
                <input ref={passwordRef} type="password" name="password" id="password" required/>

                <button onClick={e => handleLoginWithEmailAndPassword(e)} type="submit">Login</button>
              </form>

              <hr className="or-line" value="or" />

              <button type="button" className="signInBtn" onClick={handleGoogleSignIn}>
                  <FcGoogle className='signIcon'/> <span className='signInBtnText'>Sign in with Google</span>
              </button>

              <div type="button" className="guest">
                  <Link onClick={() => setActiveLink("home")} to="/home" className="guestLink">Continue as Guest</Link>
              </div>
              
              <div className="signup">
                <p>Don't have an account? <Link className="signupLink" to="/signup">Sign up</Link></p>
              </div>

              <div className="forgotPassword">
                <p>Forgot password? <Link className="forgotPasswordLink" to="/forgotPassword">Reset</Link></p>
              </div>
            </div>
        </div>
    </div> 
  )
}