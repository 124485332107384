import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../css/editor/EditableModal.css';

export default function EditableModal(props) {
  const { initialText, label } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState(initialText);
  const [fontSize, setFontSize] = useState(
    localStorage.getItem("fontSize") || "18"
  );

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  useEffect(() => {
    localStorage.setItem("fontSize", fontSize);
  }, [fontSize]);

  const handleTextChange = (event) => {
    setText(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setFontSize(localStorage.getItem("fontSize") || "18");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFontSizeChange = (event) => {
    setFontSize(event.target.value);
  }; 

  return (
    <div>
      <label onClick={handleOpenModal} className="editable-label">{label}</label>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Editable Modal"
        className="emodal"
        overlayClassName="emodal-overlay"
      >
        <label>Editing {label}</label>
        <textarea
          className="textarea"
          style={{ fontSize: `${fontSize}px` }}
          value={text}
          onChange={handleTextChange}
          autoFocus
        />
        <div className="footer">
          <div className="slider-container">
            <input
              type="range"
              min="8"
              max="28"
              value={fontSize}
              onChange={handleFontSizeChange}
              className="slider"
            />
            <span className="font-size">{fontSize}px</span>
          </div>
          <button onClick={handleCloseModal} className="close-button">Close</button>
        </div>
      </Modal>
    </div>
  );
}