import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import HasSections from '../EditContents/HasSections';

export default function CustomTitleSettings(props) {
  const { config, setConfig, setCustomTitleView, customTitleView, panelName } = props;
  const [title, setTitle] = useState('');
  const [customTitleSection, setCustomTitleSection] = useState('');

  const getItemProperties = (item, customTitleSection) => {
    if (!customTitleSection) {
      return item;
    }

    const keys = customTitleSection.split('.');
    let currentItem = item;
    for (const key of keys) {
      currentItem = currentItem?.[key];
    }
    return currentItem;
  };
  
  useEffect(() => {
    const item = getItemProperties(config['custom-title'], customTitleSection);
    if (item) {
        setTitle(item.title || '');
    }else{
        setTitle('');
    }
  }, [config, customTitleSection]); // Ensure effect runs when config or customTitleSection changes

  const handleInputChange = (e, stateSetter, key) => {
    const value = e.target.value;
    stateSetter(value);  // Update local state with the new value

    // Get current item properties; this should fetch the relevant section from your config
    const currentItem = getItemProperties(config['custom-title'], customTitleSection);

    // Update the current item with the new key-value pair
    const updatedItem = { ...currentItem, [key]: value };

    // Update the config section based on the path provided by customTitleSection
    let updatedConfigSection = {...config['custom-title']}; // Start with a copy of the current config

    if (customTitleSection) {
        const path = customTitleSection.split('.');
        let target = updatedConfigSection;
        for (let i = 0; i < path.length - 1; i++) {
            // Ensure each part of the path exists
            target[path[i]] = target[path[i]] || {};
            target = target[path[i]];
        }
        // Set the new value on the last part of the path
        target[path[path.length - 1]] = updatedItem;
    } else {
        // If no path is provided, directly update the root of the custom-title section
        updatedConfigSection = updatedItem;
    }

    // Send the updated config section back to the parent component or handler
    props.updatePanelSetting("custom-title", updatedConfigSection);
};

  const inputStyle = {
    textAlign: 'left',
    background: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    width: '255px',
    height: '22px',
    backgroundColor: '#2b4f65',
    borderColor: '#1e2126',
    color: 'white',
  };

  const labelStyle = {
    display: 'inline-block',
    width: '220px',
    marginBottom: '5px',
  };

  const buttonStyle = {
    position: 'absolute',
    width: '100px',
    right: '10px',
    padding: '5px',
  };

  const deleteButton = {
    position: 'absolute',
    backgroundColor: 'red',
    width: '100px',
    right: '120px',
    padding: '5px',
  };

  return (
    <div style={{ padding: '3px' }}>
      <button style={buttonStyle} onClick={() => setCustomTitleView(!customTitleView)}>
        {customTitleView ? "Advanced" : "Basic"}
      </button>
      <p>
        <label id="hotbarItemLabel" style={labelStyle}>Custom Title</label>
      </p>
      <p>
        <label style={labelStyle}>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => handleInputChange(e, setTitle, 'title')}
          style={inputStyle}
        />
      </p>
      {customTitleView && (
        <div>
            <label style={labelStyle}>Has Sections:</label>
            <HasSections 
                setConfig={setConfig}
                config={config}
                currentSlot={"custom-title"}
                panelName={panelName}
                currentSection={customTitleSection} 
                setCurrentSection={setCustomTitleSection}
            />
        </div>
      )}
    </div>
  );
};