import React, { useEffect, useState } from 'react'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";
import { getUserDetails } from '../firebase/firebaseFunctions.js';
import "../css/home/home.scss";
import { FaDiscord, FaGithub, FaBookOpen, FaEdit, FaPatreon } from 'react-icons/fa';

export default function Home({toggleHamburger}) {
  const [user, loading, error] = useAuthState(auth);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    if (loading) {
      return;
    }
    async function get() {
      try {
          const doc = await getUserDetails(user.uid);
          if (doc) {
            setUserDetails(doc);
          } else {
            setUserDetails("Guest");
            }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    }
    if (user === null) {
      setUserDetails("Guest");
    } else {
      get();
    }
  }, [loading, user]);

  return (
    userDetails === null ? <div>Loading...</div>
    : <div className="homeContainer">
        <header className={!toggleHamburger ? "homeContainer active" : ""}>
          <div className="details-text">
            <h1>CommandPanels</h1>
            <p>A custom GUI plugin for Minecraft Servers.</p>
          </div>
          <div className="download">
            <a href="/download">
              <button id="jarButton">
                Download
                <span>Available Versions</span>
              </button>
            </a>
          </div>
        </header>

        {/* content section */}
        <section className={!toggleHamburger ? "content active" : "content"}>
          <div id="homeText">
            <h1>Welcome to CommandPanels</h1>
            <p>Command Panels is a powerful Minecraft plugin that allows you to easily create custom GUIs using simple YAML files. With our online editor, you can easily customize your panels and manage them from anywhere, without needing to manually edit files on your server.</p>
            <p>Our plugin offers a wide range of features, including animations, logic, and data tools that allow you to create advanced and fully-customized menu experiences. The player inventory feature also allows you to extend the GUI into the player's inventory area, creating even more opportunities for customizability.</p>
            <p>One of the standout features of Command Panels is its ease of use. Unlike other plugins that require complicated editing of YAML files, our online editor simplifies the process to allow for even more flexibility and creativity. Whether you're an experienced developer or new to creating custom Minecraft GUIs, Command Panels offers an intuitive and user-friendly experience.</p>
            <p>Our developer API also allows for easy integration into your own plugins, making it a great choice for those looking to expand their Minecraft server functionality. Plus, Command Panels is available to use for free.</p>
            <p>Experience the power of Command Panels for yourself and take your Minecraft server to the next level. Get started today!</p>
          </div>
          <div id="homeButtons">
          <button className="discordButton homeButton">
              <a href="https://discord.gg/eUWBWh7" target="_blank">
                <div className="buttonContent">
                  <FaDiscord className="buttonIcon" />
                  <div className="buttonTextContainer">
                    <span className="buttonText">Discord</span>
                    <span className="buttonSubtitle">Join the community Discord Server</span>
                  </div>
                </div>
              </a>
            </button>

            <button className="homeButton">
              <a href="/wiki">
                <div className="buttonContent">
                  <FaBookOpen className="buttonIcon"/>
                  <div className="buttonTextContainer">
                    <span className="buttonText">Wiki</span>
                    <span className="buttonSubtitle">Learn more about our plugin</span>
                  </div>
                </div>
              </a>
            </button>

            <button className="githubButton homeButton">
              <a href="https://github.com/rockyhawk64/CommandPanels" target="_blank">
                <div className="buttonContent">
                  <FaGithub className="buttonIcon" />
                  <div className="buttonTextContainer">
                    <span className="buttonText">Github</span>
                    <span className="buttonSubtitle">See the plugins source code</span>
                  </div>
                </div>
              </a>
            </button>

            <button className="editorButton homeButton">
              <a href="https://www.patreon.com/commandpanels" target="_blank">
                  <div className="buttonContent">
                    <FaPatreon className="buttonIcon" />
                    <div className="buttonTextContainer">
                      <span className="buttonText">Patreon</span>
                      <span className="buttonSubtitle">Donate and keep the project running</span>
                    </div>
                  </div>
                </a>
            </button>

            <button className="editorButton homeButton">
              <a href="/partner">
                  <div className="buttonContent">
                    <FaEdit className="buttonIcon" />
                    <div className="buttonTextContainer">
                      <span className="buttonText">Make a Server</span>
                      <span className="buttonSubtitle">Use our Promocode to start your Minecraft Server</span>
                    </div>
                  </div>
                </a>
            </button>
          </div>
        </section>
      </div>
  );
}