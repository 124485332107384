import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import "../../css/editor/hassection.css";

const TreeNode = ({ section, onClick, currentAnimateFrame }) => {
  const frameNumber = section.replace("animate", "");

  return (
    <div
      key={section}
      onClick={() => onClick(section)}
      style={{
        cursor: "pointer",
        fontWeight: currentAnimateFrame === section ? "bold" : "normal",
        paddingLeft: 15,
      }}
    >
      {`Frame ${frameNumber}`}
    </div>
  );
};

export default function AnimationWindow(props) {
  const {
    setConfig,
    config,
    currentSlot,
    currentSection,
    currentAnimateFrame,
    setCurrentAnimateFrame,
  } = props;

  const [slotPrefix, actualSlot] = currentSlot.includes('.')
    ? currentSlot.split('.')
    : ['', currentSlot];

  const [sections, setSections] = useState([]);

  const handleNodeClick = (sectionKey) => {
    setCurrentAnimateFrame(sectionKey);
  };

  //play animations when toggled
  const [isPlaying, setIsPlaying] = useState(false);
  const [frameIndex, setFrameIndex] = useState(0);
  useEffect(() => {
    let interval;
  
    if (isPlaying) {
      let ticks = 20;
      if (!isNaN(parseInt(config['refresh-delay'], 10))) {
        ticks = parseInt(config['refresh-delay'], 10);
      }
      const intervalDuration = ticks * 50;
      const totalFrames = parseInt(config['animatevalue'], 10) + 1;
  
      interval = setInterval(() => {
        setFrameIndex((prevIndex) => {
          if (prevIndex + 1 < totalFrames) {
            return prevIndex + 1;
          } else {
            return 0;
          }
        });
      }, intervalDuration);
    }
  
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isPlaying, config]);  

  useEffect(() => {
    if (isPlaying) {
      const frameKey = `animate${frameIndex}`;
      setCurrentAnimateFrame(frameKey);
    }
  }, [frameIndex, sections]);  

  const handlePlayClick = () => {
    setIsPlaying((prevState) => !prevState);
  };

  //update sections with the ones found on the slot
  useEffect(() => {
      const slotConfig = slotPrefix
        ? currentSection
          ? config[slotPrefix]?.[actualSlot]?.[currentSection]
          : config[slotPrefix]?.[actualSlot]
        : currentSection
          ? config?.[actualSlot]?.[currentSection]
          : config?.[actualSlot];
      const newSections = [];
  
      if (slotConfig) {
      for (const key in slotConfig) {
          if (key.startsWith("animate")) {
          newSections.push(key);
          }
      }
      }
  
      setSections(newSections);
  }, [config, actualSlot, currentSection]);

  //when slot changed, set section back to the default section and stop animating
  useEffect(() => {
      setIsPlaying(false);
      setCurrentAnimateFrame(undefined);
  }, [actualSlot, currentSection]);

    //used for manageSection so that the buttons can call the three types
    const handleAddSection = () => manageSection("add");
    const handleCopySection = () => manageSection("copy");

    /**
     * A function to manage sections based on the provided type.
     * 
     * @param {string} type - The type of section management. It can be 'add', 'copy'
     */
    const manageSection = async (type) => {
      const slotConfig = slotPrefix
        ? currentSection
          ? config[slotPrefix]?.[actualSlot]?.[currentSection]
          : config[slotPrefix]?.[actualSlot]
        : currentSection
          ? config?.[actualSlot]?.[currentSection]
          : config?.[actualSlot];
    
      if (type === "copy" && !currentAnimateFrame) {
        await swal.fire({
          title: "Error",
          text: "Cannot copy the default frame.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return;
      }
    
      if (type === "add" && !slotConfig) {
        await swal.fire({
          title: "Error",
          text: "Cannot add an animation frame to a slot with no default item.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return;
      }
    
      const { value: frameIndex } = await swal.fire({
        input: 'number',
        inputLabel: type === "add" ? 'Frame Number' : 'New Frame Number',
        inputPlaceholder: 'Enter the number of the new frame',
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter a number!';
          } else if (sections.includes(`animate${value}`)) {
            return 'That frame already exists!';
          }
        },
      });
    
      if (frameIndex !== undefined) {
        const newSectionKey = `animate${frameIndex}`;
    
        const updatedConfig = { ...config };
        const current = slotPrefix
          ? currentSection
            ? updatedConfig[slotPrefix]?.[actualSlot]?.[currentSection]
            : updatedConfig[slotPrefix]?.[actualSlot]
          : currentSection
            ? updatedConfig?.[actualSlot]?.[currentSection]
            : updatedConfig?.[actualSlot];
    
        if (type === "copy") {
          // Copy the content of the current frame to the new frame
          current[newSectionKey] = { ...current[currentAnimateFrame] };
        } else {
          current[newSectionKey] = {};
        }
    
        setConfig(updatedConfig);
        setCurrentAnimateFrame(newSectionKey);
      }
    };
    
    
    const removeSection = async () => {
      if (!currentAnimateFrame) {
        await swal.fire({
          title: "Error",
          text: "Cannot delete the default frame.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return;
      }
      
      const result = await swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this frame!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      
      if (result.isConfirmed) {
        const updatedConfig = { ...config };
        const current = slotPrefix
          ? currentSection
            ? updatedConfig[slotPrefix]?.[actualSlot]?.[currentSection]
            : updatedConfig[slotPrefix]?.[actualSlot]
          : currentSection
            ? updatedConfig?.[actualSlot]?.[currentSection]
            : updatedConfig?.[actualSlot];
        delete current[currentAnimateFrame];
        setConfig(updatedConfig);
    
        const newSections = sections.filter((sectionKey) => sectionKey !== currentAnimateFrame);
        setSections(newSections);
        setCurrentAnimateFrame(undefined);
      }
    };      

    return (
      <div className="hasSectionContainer">
        <div className="sectionsList">
          <div
            onClick={() => setCurrentAnimateFrame(undefined)}
            style={{
              cursor: "pointer",
              fontWeight: !currentAnimateFrame ? "bold" : "normal",
            }}
          >
            Default
          </div>
          {sections.map((sectionKey) => (
            <TreeNode
              key={sectionKey}
              section={sectionKey}
              onClick={handleNodeClick}
              currentAnimateFrame={currentAnimateFrame}
            />
          ))}
        </div>
        <div className={"sectionActions"}>
          <button onClick={handleAddSection}>Add Frame</button>
          <button onClick={handleCopySection}>Copy Frame</button>
          <button onClick={handlePlayClick}>{isPlaying ? "Pause" : "Play"}</button>
          {currentAnimateFrame !== undefined && (
            <button style={{ backgroundColor: "red" }} onClick={removeSection}>Del</button>
          )}
        </div>
      </div>
    );
}