import React, { useState, useEffect } from 'react';
import swal from 'sweetalert2';
import "../../css/editor/hassection.css";
import LogicCreator from "../util/LogicCreator.js";

const TreeNode = ({ config, section, level, onClick, selected, currentSection, slotPrefix, actualSlot }) => {
    const getNestedSections = (config, section) => {
      let current = slotPrefix ? config[slotPrefix]?.[actualSlot] : config?.[actualSlot];
      const keys = section.split(".");
      for (const key of keys) {
        current = current?.[key];
      }
      return Object.keys(current || {})
        .filter((key) => key.startsWith("has"))
        .sort();
    };
  
    const nestedSections = getNestedSections(config, section);
  
    const hasSection = section.split('.').pop();
    const displaySectionKey = `Section ${hasSection.split('has')[1]}`;
  
    return (
      <div key={section} style={{ paddingLeft: 15 }}>
        <div
          onClick={() => onClick(section)}
          style={{
            cursor: "pointer",
            fontWeight: selected ? "bold" : "normal",
          }}
        >
          {displaySectionKey}
        </div>
        {nestedSections.map((nestedSectionKey) => (
          <TreeNode
            key={nestedSectionKey}
            config={config}
            section={`${section}.${nestedSectionKey}`}
            level={level + 1}
            onClick={onClick}
            selected={currentSection === `${section}.${nestedSectionKey}`}
            currentSection={currentSection}
            slotPrefix={slotPrefix}
            actualSlot={actualSlot}
          />
        ))}
      </div>
    );
  };  

  export default function HasSections(props) {
    const {
      setConfig,
      config,
      currentSlot,
      currentSection,
      setCurrentSection,
    } = props;
  
    const [slotPrefix, actualSlot] = currentSlot.includes('.')
      ? currentSlot.split('.')
      : ['', currentSlot];
    
    const [sections, setSections] = useState([]);
    
    const handleNodeClick = (sectionKey) => {
        setCurrentSection(sectionKey);
    };
    
    //update sections with the ones found on the slot
    useEffect(() => {
        const slotConfig = slotPrefix ? config[slotPrefix]?.[actualSlot] : config?.[actualSlot];
        const newSections = [];
    
        if (slotConfig) {
        for (const key in slotConfig) {
            if (key.startsWith("has")) {
            newSections.push(key);
            }
        }
        }
    
        setSections(newSections);
    }, [config, actualSlot]);
    
    //when slot changed, set section back to the default section
    useEffect(() => {
      setCurrentSection(undefined);
    }, [actualSlot]); 

    //used for manageSection so that the buttons can call the three types
    const handleAddSection = () => manageSection("sibling");
    const handleCopySection = () => manageSection("copy");
    const handleAddNestedSection = () => manageSection("nested");

    /**
     * A function to manage sections based on the provided type.
     * 
     * @param {string} type - The type of section management. It can be 'nested', 'sibling', 'copy', etc.
     */
    const manageSection = async (type) => {
      // Determine the current slot configuration based on the presence of a slot prefix.
      // If the slot prefix exists, we drill down two levels in the config object to get the config for the actual slot.
      // If not, we simply get the config of the actual slot directly from the config object.
      const slotConfig = slotPrefix ? config[slotPrefix]?.[actualSlot] : config?.[actualSlot];
      
      // If we couldn't find a configuration for the slot, display an error message to the user.
      if (!slotConfig) {
        await swal.fire({
          title: "Error",
          text: "Cannot add a section to a slot with no default item.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        });
        return;
      }
    
      let newSectionKey;
      // Create a mutable copy of the current configuration.
      const updatedConfig = { ...config };
      // Initialize the current variable based on the presence of the slot prefix.
      let current = slotPrefix ? updatedConfig[slotPrefix]?.[actualSlot] : updatedConfig?.[actualSlot];
    
      // If we have a current section, process it to get the section keys and possibly the parent section.
      if (currentSection) {
        const sectionKeys = currentSection.split(".");
        let parentSection;
        // If the type of management is 'nested', we are adding a child to the current section.
        if (type === "nested") {
          for (const key of sectionKeys) {
            current = current[key];
          }
        
          // Find the index of the next child to add by checking for existing children.
          let nextChildIndex = 0;
          while (Object.keys(current).includes(`has${nextChildIndex}`)) {
            nextChildIndex++;
          }
          newSectionKey = `${currentSection}.has${nextChildIndex}`;
        } else if (type === "sibling" || type === "copy") {
          // If the type is 'sibling' or 'copy', we are adding a sibling section (at the same level as the current section).
      
          // Determine the parent section of the current section.
          parentSection = sectionKeys.slice(0, -1).join(".");
          let currentParent = parentSection
            ? parentSection.split(".").reduce((acc, val) => acc[val], slotConfig)
            : slotConfig;
          
          // Determine the key/index of the next sibling section.
          let nextSiblingIndex = 1;
          const lastKey = currentSection.split(".").pop();
          const baseKey = parseInt(lastKey.split("has")[1]);
          while (Object.keys(currentParent).includes(`has${baseKey + nextSiblingIndex}`)) {
            nextSiblingIndex++;
          }
          newSectionKey = parentSection
            ? `${parentSection}.has${baseKey + nextSiblingIndex}`
            : `has${baseKey + nextSiblingIndex}`;
      

          // If the type is 'copy', we need to duplicate the current section.
          // Otherwise, we just initialize an empty section.
          if (type === "copy") {
            let originalSection = sectionKeys.reduce((acc, val) => acc[val], slotConfig);
            currentParent[newSectionKey.split(".").pop()] = { ...originalSection };
          } else {
            currentParent[newSectionKey.split(".").pop()] = {};
          }
        }
      } else {
        // If we don't have a current section, we are adding a root-level section.

        // Determine the key/index for the next root section.
        let nextRootIndex = 0;
        while (sections.includes(`has${nextRootIndex}`)) {
          nextRootIndex++;
        }
        newSectionKey = `has${nextRootIndex}`;
        current[newSectionKey] = {};
      }
    
      // If the type isn't 'copy', we need to traverse the configuration again and
      // ensure that each section level in the path exists. If not, we initialize it.
      if (type !== "copy") {
        const newSectionKeys = newSectionKey.split(".");
        let current = slotPrefix ? updatedConfig[slotPrefix]?.[actualSlot] : updatedConfig?.[actualSlot];
        
        for (let i = 0; i < newSectionKeys.length - 1; i++) {
          current = current[newSectionKeys[i]];
          if (!current) {
            current = current[newSectionKeys[i]] = {};
          }
        }
        
        current[newSectionKeys[newSectionKeys.length - 1]] = {};
      }
      
      // Update the state with the new configuration and set the current section to the newly added section.
      setConfig(updatedConfig);
      setCurrentSection(newSectionKey);
    };
  
  const removeSection = async () => {
    if (!currentSection) {
      await swal.fire({
        title: "Error",
        text: "Cannot delete the default section.",
        icon: "error",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
      return;
    }
  
    const result = await swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this section or any child sections!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
  
    if (result.isConfirmed) {
      const updatedConfig = { ...config };
      const sectionKeys = currentSection.split(".");
      let parent = slotPrefix ? updatedConfig[slotPrefix]?.[actualSlot] : updatedConfig?.[actualSlot];
  
      for (let i = 0; i < sectionKeys.length - 1; i++) {
        parent = parent[sectionKeys[i]];
      }
  
      delete parent[sectionKeys[sectionKeys.length - 1]];
      setConfig(updatedConfig);
  
      const newSections = sections.filter(
        (sectionKey) => !currentSection.startsWith(sectionKey)
      );
      setSections(newSections);
      setCurrentSection(undefined);
    }
  };  

  //this will convert conditions to strings and update them in the config file
  const handleLogicCreatorChange = (newConditions) => {
    if (config && actualSlot && currentSection) {
      const updatedConfig = JSON.parse(JSON.stringify(config));
  
      let current = slotPrefix ? updatedConfig[slotPrefix]?.[actualSlot] : updatedConfig?.[actualSlot];
      const sectionKeys = currentSection.split('.');
      for (let i = 0; i < sectionKeys.length; i++) {
        const key = sectionKeys[i];
        if (i === sectionKeys.length - 1) {
          newConditions.forEach((condition, index) => {
            current[key][`value${index}`] = `${condition.conditionType ? condition.conditionType + ' ' : ''}${condition.value} ${condition.compareType}`.trim();
            current[key][`compare${index}`] = `${condition.compareValue}${index < newConditions.length - 1 ? ` ${condition.logic || 'OR'}` : ''}`.trim();
          });
        } else {
          current = current[key];
        }
      }
  
      setConfig(updatedConfig);
    }
  };

  //when remove condition is pressed this handles removing the condition from the config file
  const removeLastCondition = () => {
    if (config && actualSlot && currentSection) {
      const updatedConfig = JSON.parse(JSON.stringify(config));
  
      let current = slotPrefix ? updatedConfig[slotPrefix]?.[actualSlot] : updatedConfig?.[actualSlot];
      const sectionKeys = currentSection.split('.');
      for (let i = 0; i < sectionKeys.length; i++) {
        const key = sectionKeys[i];
        if (i === sectionKeys.length - 1) {
          let index = 0;
          while (true) {
            if (!current[key][`value${index}`] && !current[key][`compare${index}`]) {
              break;
            }
            index++;
          }
          delete current[key][`value${index - 1}`];
          delete current[key][`compare${index - 1}`];
        } else {
          current = current[key];
        }
      }
  
      setConfig(updatedConfig);
    }
  };

  return (
    <div className="hasSectionsContainer">
      <div className="sectionsList">
        <div
          onClick={() => setCurrentSection(undefined)}
          style={{
            cursor: "pointer",
            fontWeight: !currentSection ? "bold" : "normal",
          }}
        >
          Default
        </div>
        {sections.map((sectionKey) => (
        <TreeNode
            key={sectionKey}
            config={config}
            section={sectionKey}
            level={1}
            onClick={handleNodeClick}
            selected={currentSection === sectionKey}
            currentSection={currentSection}
            slotPrefix={slotPrefix}
            actualSlot={actualSlot}
        />
        ))}
      </div>
      <div className={"sectionActions"}>
        <button onClick={handleAddSection}>Add Section</button>
        <button onClick={handleAddNestedSection}>Add Nested Section</button>
        <button onClick={handleCopySection}>Copy Section</button>
        {currentSection !== undefined && (
          <button style={{ backgroundColor: "red" }} onClick={removeSection}>Del</button>
        )}
      </div>
      {currentSection !== undefined && (
        <div className={"sectionProperties"}>
            <LogicCreator onChange={handleLogicCreatorChange} config={config} removeIndex={removeLastCondition} currentSection={currentSection} actualSlot={actualSlot} slotPrefix={slotPrefix}/>
        </div>
        )}
    </div>
  );
}
