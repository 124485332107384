// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAeVNjRJRiWzMXuwK1Pkf0i2CeNxYV4KPU",
  authDomain: "commandpanels-website.firebaseapp.com",
  projectId: "commandpanels-website",
  storageBucket: "commandpanels-website.appspot.com",
  messagingSenderId: "408872339375",
  appId: "1:408872339375:web:05d1253c38744ba3ef915a",
  measurementId: "G-9BBWM2BQ1V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };