import { ref, getStorage, uploadBytes, deleteObject, getDownloadURL, listAll } from 'firebase/storage';
import yaml from 'js-yaml';
import Swal from 'sweetalert2';

export const createRepository = async (userId, username, panelFile, panel, name, description, commandPanelsVersion, minecraftVersion, image) => {
    const storage = getStorage();
    const uniqueUploadId = Date.now().toString(); // Create a unique ID based on the current timestamp
    const uploadPath = `repo/${uniqueUploadId}/`;
    
    // Save the details as a JSON file
    const details = {
      owner: userId,
      username,
      panelFile,
      name,
      description,
      commandPanelsVersion,
      minecraftVersion,
      lastModified: Date.now(),
    };

    //clear cached repository panels
    clearPanelsCache();
  
    // Convert the details object to a JSON string and save it as a Blob
    const detailsBlob = new Blob([JSON.stringify(details)], { type: 'application/json' });
    const detailsRef = ref(storage, `${uploadPath}details.json`);
    await uploadBytes(detailsRef, detailsBlob);
  
    // Upload the panel file (.yml) to the repository
    const panelRef = ref(storage, `${uploadPath}${panelFile}`);
    const panelBlob = new Blob([yaml.dump(panel)], { type: 'text/yaml' });
    await uploadBytes(panelRef, panelBlob);
  
    // Upload the image to the repository
    const imageRef = ref(storage, `${uploadPath}image.png`);
    await uploadBytes(imageRef, image);
  
    return uniqueUploadId;
  };

  export const updateRepository = async (
    displayname,
    uploadId,
    panelFile,
    panel,
    name,
    description,
    commandPanelsVersion,
    minecraftVersion,
    image
  ) => {
    const storage = getStorage();
    const uploadPath = `repo/${uploadId}/`;
    const detailsRef = ref(storage, `${uploadPath}details.json`);
    
    //clear cached repository panels
    clearPanelsCache();
  
    // Fetch the current details
    const downloadURL = await getDownloadURL(detailsRef);
    const response = await fetch(downloadURL);
    const existingDetails = await response.json();
    console.log(description);
  
    // Update the details with the new data
    const updatedDetails = {
      ...existingDetails,
      owner: existingDetails.owner, // Keep the owner unchanged
      username: displayname,
      panelFile: panelFile !== null ? panelFile : existingDetails.panelFile,
      name,
      description,
      commandPanelsVersion,
      minecraftVersion,
      lastModified: Date.now(),
    };
    console.log(updatedDetails);
  
    // Upload the updated details file
    const detailsBlob = new Blob([JSON.stringify(updatedDetails)], {
      type: "application/json",
    });
    await uploadBytes(detailsRef, detailsBlob);
  
    if (panelFile !== null && panel !== null) {
      // Update the panel file (.yml) in the repository
      const panelRef = ref(storage, `${uploadPath}${panelFile}`);
      const panelBlob = new Blob([yaml.dump(panel)], { type: "text/yaml" });
      await uploadBytes(panelRef, panelBlob);
    }
  
    // Update the image in the repository
    if (image) {
      const imageRef = ref(storage, `${uploadPath}image.png`);
      await uploadBytes(imageRef, image);
    }
  };

  export const deleteRepository = async (uploadId) => {
    const storage = getStorage();
    const uploadPath = `repo/${uploadId}/`;
    const folderRef = ref(storage, uploadPath);
    const list = await listAll(folderRef);

    const deleteItems = async (items) => {
        for (const item of items) {
            try {
                await deleteObject(ref(storage, item.fullPath));
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        }
    };

    // Delete all the files in the repository folder
    await deleteItems(list.items);

    //clear cached repository panels
    clearPanelsCache();

    // Notify user that the repository was deleted
    await Swal.fire('Deleted!', 'Your uploaded panel has been deleted.', 'success');
};

//clear the cache for all of the panels in the repo
const clearPanelsCache = () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('panels_cache_')) {
        localStorage.removeItem(key);
      }
    }
  };

//panelIds could be one Id or a list of Ids
export const getRepositories = async (panelIds = null) => {
    const storage = getStorage();
    const folderRef = ref(storage, 'repo/');
    const list = await listAll(folderRef);
    const repositories = [];

    const fetchDetails = async (item) => {
        // Ensure panelIds is always an array if only one id is provided
        const panelIdsArray = Array.isArray(panelIds) ? panelIds : [panelIds];

        // Skip if we are filtering by panelIds and it doesn't match
        if (panelIds && !panelIdsArray.includes(item.name)) return;

        //get the details from the panel details file
        const detailsRef = ref(storage, `${item.fullPath}/details.json`);
        const downloadURL = await getDownloadURL(detailsRef);
        const response = await fetch(downloadURL);
        const details = await response.json();
        details.panelId = item.name;

        //get the uploaded image
        try {
            const imageRef = ref(storage, `${item.fullPath}/image.png`);
            const imageUrl = await getDownloadURL(imageRef);
            details.imageUrl = imageUrl;
        } catch (error) {
            console.error('Error fetching image:', error);
            details.imageUrl = '';
        }

        repositories.push(details);
    };

    // Fetch the details and images in parallel
    await Promise.all(list.prefixes.map(fetchDetails));

    return repositories;
};

export const getPanelIds = async () => {
    const storage = getStorage();
    const folderRef = ref(storage, 'repo/');
    const list = await listAll(folderRef);
    const panelIds = [];

    // Extract the timestamps and panelIds from the folder names
    list.prefixes.forEach(item => {
        const timestamp = parseInt(item.name, 10);
        if (!isNaN(timestamp)) {
            panelIds.push({
                timestamp: timestamp,
                panelId: item.name
            });
        }
    });

    // Return only the panelIds
    return panelIds.map(item => item.panelId);
};



